import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import BTable from 'react-bootstrap/Table';
import "./table.css";
import  {useTable, useFilters, useSortBy, usePagination}  from 'react-table';
import {inclFilterFn, activityFilterFn, DefaultColumnFilter, inclPersonFilterFn} from "../../../utils/Filters";
import {adminTranslations} from "../../lang/language";
import {useLanguage} from "../../../context/LanguageContext"
export default function Table({ columns, data, fit, pageSizeRaw }){
    const {language} = useLanguage();


    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )



    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const filterTypes = React.useMemo(
        () => ({
            inclPersonFilterFn: inclPersonFilterFn,
            inclFilterFn: inclFilterFn,
            activityFilterFn: activityFilterFn,
            text: (rows, id, filterValue) => {

                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const { getTableProps, headerGroups, page, prepareRow, selectedFlatRows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { selectedRowIds, pageIndex, pageSize } } = useTable({
        columns,
        data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                sortBy: [
                    {
                        id: 'dateTime',
                        desc: true
                    }
                ],
            pageIndex: 0
            },

    },


        useFilters,
        useSortBy,
        usePagination,

        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
              //  {
                 //   id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox


                    /* Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),*/


                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    /*
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                    */
               // },
                ...columns,
            ])
        })

    let pages = [];
    for (let pg = 0; pg < pageCount; pg++) {
        let btn = <button key={pg} onClick={() => gotoPage(pg)}>{pg+1}</button>;
        if(pg === pageIndex)
            btn = <button key={pg} className="currentPage" onClick={() => gotoPage(pg)}>{pg+1}</button>;
        pages.push(btn);
    }

    useEffect(()=>{
        setPageSize(pageSizeRaw);
    }, [])

    return(

        <div>
            <BTable className={fit ? "fit" : ""} striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, id) => (
                            <th key={id}>
                                <div {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted
                                    ? column.isSortedDesc
                                        ?  'asc'
                                        :  'desc': 'def'}>{column.render('Header')}</div>

                                <div className='filterInput'>{column.canFilter ? column.render('Filter') : <div><br/></div>}</div>

                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr key={i} {...row.getRowProps()}>
                            {row.cells.map((cell,a) => {
                                return (
                                    <td  key={a} {...cell.getCellProps()}>

                                        {cell.render('Cell')}


                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>

            </BTable>
            <div className="paging_simple_numbers_block">
                <div className="paging_simple_numbers">
                    <button className="paginate_button previous " onClick={() => previousPage()} disabled={!canPreviousPage} id="datatable2_previous">{adminTranslations.prevPageText[language]}</button>
                    {pages}
                    <button className="paginate_button next" onClick={() => nextPage()} disabled={!canNextPage}>{adminTranslations.nextPageText[language]}</button>
                </div>
            </div>
        </div>


    );
}