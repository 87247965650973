import {useTranslation} from "react-i18next";
import OneSideOptionResult from "./OneSideOptionResult";
import TwoSideOptionResult from "./TwoSideOptionResult";
import PictureOptionResult from "./PictureOptionsResult";
import {useSelector} from "react-redux";
import React from "react";
import {getMobile} from "../user/settingsSlice";

const ItemQuestionResult = ({item, index}) => {
    const { t } = useTranslation();
    const isMobile = useSelector(getMobile)
    let options;

        switch(item.answerView){
            case "TEXT_TWO_PARTED":
                options = <TwoSideOptionResult options={item.options} />
                break;
            case "TEXT_SINGLE_PART":
                options = <OneSideOptionResult options={item.options}/>
                break;
            case "PICTURE":
                options = <PictureOptionResult options={item.options}/>
                break;
        }


    return <div className={isMobile ? "questionResultBlockMainMobile":"questionResultBlockMain"}>
        <div className={item.correct ? "numberQuestionCorrect" : "numberQuestionWrong"}>
            {index+1}
        </div>
        <div className={item.correct ? "questionResultBlock greenBorder greenShadow" : "questionResultBlock redBorder redShadow"}>
            <h3>{t('Question')+':'}{!item.answersAre && <span className="noAnswers">{t("NoAnswers")}</span>}</h3>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
            <div className="newDescription" dangerouslySetInnerHTML={{__html: item.description}}></div>
            <br/>
            <h3>{t('YourAnswer')+':'}</h3>
                {options}
            <br/>
            {
                item.explanation &&<><h3>{t('Explanation')+':'}</h3>
                <div className="newExplanation" dangerouslySetInnerHTML={{__html: item.explanation}}></div>
                </>
            }

        </div>
    </div>
}

export default ItemQuestionResult;