import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {

    selectPhone, selectPhoneError,
 setPhone
} from "../../features/auth/authSlice";
import 'react-international-phone/style.css';
import {getCountry} from "../../features/user/settingsSlice";

import {PhoneInput} from "react-international-phone";



const InputPhone = ({classes}) => {

    const dispatch = useDispatch();
    const country = useSelector(getCountry)
    const phone = useSelector(selectPhone)
    const phoneError = useSelector(selectPhoneError)

    return  country.country_code.length > 0 ? <div className="inputDivWithMessage"><PhoneInput
            defaultCountry={country.country_code.toLocaleLowerCase()}
            value={phone ? phone : ""}
            className={classes}
            onChange={(e)=>dispatch(setPhone(e))}/>
            <div>{phoneError}</div>
        </div> : <div></div>

}

export default InputPhone;