import {createSlice, current} from "@reduxjs/toolkit";

const initialState = {
    answers: []
}

const testAnswersSlice = createSlice({
        name: '@@testAnswersSlice',
        initialState: initialState,
        reducers: {
            setAnswers:(state, action)=>{
                state.answers = action.payload;
            },
            addAnswer:(state, action)=>{

                const {side, idAnswer, idTest, multiplicity} = action.payload
                const index = state.answers.findIndex((obj => (obj.key === idTest)));


                if(index !== -1 ){

                    const answer = state.answers[index]

                    const insideAnswers = answer.answers.filter(answerValue=>answerValue.side === side)

                    if(insideAnswers.length > 0){
                        const answerValue = insideAnswers[0].value
                       if(multiplicity === 1){
                            answerValue[0] = idAnswer
                        }
                        else if(multiplicity > 1 && answerValue.length < multiplicity && !answerValue.includes(idAnswer)){
                                answerValue.push(idAnswer)
                        }
                        else if( answerValue.includes(idAnswer)){
                                answerValue.splice(answerValue.indexOf(idAnswer), 1)
                        }
                    }else{
                        answer.answers.push({side: side, value: [parseInt(idAnswer)]})

                    }
                }
                else{
                    state.answers.push({key: idTest, answers: [{side: side, value: [parseInt(idAnswer)]}]})
                }

            },
        },
    }
)

export const {setAnswers, addAnswer} = testAnswersSlice.actions;
export const testAnswersReducer = testAnswersSlice.reducer;
export const getAnswers = (state) => state.testAnswersSlice.answers
export const isQuestionAnswered = (state, id) => {
    return  state.testAnswersSlice.answers.filter((entity)=>entity.key === id).length !== 0
}
export const getAnswerByQuestion = (state, id) => {

    const tempArray = state.testAnswersSlice.answers.filter((entity)=>entity.key === id)

    if(tempArray.length > 0){
        const flatArray = tempArray[0].answers.flatMap(answer=>answer.value)

        return flatArray
    }
    else{
        return []
    }


}