import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import RootAddress from "../../../API/RootAddress";
import {
    GET_CERTAIN_RESULTS_BY_ID,
    GET_RESULTS_BY_CAT4_RESULT_ID,
    GET_RESULTS,
    GET_RESULTS_BY_TEST, GET_RESULTS_BY_LANGUAGE_LEVEL_RESULT_ID
} from "../../app/repository";
import {apiSlice} from "../../app/api/apiSlice";

export const resultsSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
       getResultByTestId: builder.query({
            query: id =>  GET_RESULTS_BY_TEST+id,
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
        getCertainResultById: builder.query({
            query: id =>  GET_CERTAIN_RESULTS_BY_ID+id,
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
        getResultByCat4ResultId: builder.query({
            query: id =>  GET_RESULTS_BY_CAT4_RESULT_ID+id,
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
        getResultByLanguageLevelResultId: builder.query({
            query: id =>  GET_RESULTS_BY_LANGUAGE_LEVEL_RESULT_ID+id,
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
        getResults: builder.query({
            query: () =>  GET_RESULTS,
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
    })
})

export const {
    useGetResultsQuery,
    useGetCertainResultByIdQuery,
    useGetResultByTestIdQuery,
    useGetResultByCat4ResultIdQuery,
    useGetResultByLanguageLevelResultIdQuery
} = resultsSlice