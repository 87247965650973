import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setScrollPosition} from "../features/application/applicationSlice";

export const useScrollPosition = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
        const updateVisible = () => {
            dispatch(setScrollPosition(window.pageYOffset))

        }

        window.addEventListener('scroll', updateVisible);
        return () => window.removeEventListener('scroll', updateVisible);
    },[])
}