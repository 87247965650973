import {useDispatch, useSelector} from "react-redux";

import {
    getVisibleBottomMobileBar,
    setBlackBackground,
    setBottomMobileBar, setVisibleLoginModal
} from "../../common/features/application/applicationSlice";

import React from "react";
import {selectIsAuth} from "../../common/features/auth/authSlice";
import {useTranslation} from "react-i18next";
import TopbarUserMobile from "./TopbarUserMobile";
import {Link} from "react-router-dom";
import {HomeOutlined} from "@mui/icons-material";
import {getLanguage, getLocalizedLanguage} from "../../common/features/user/settingsSlice";

const BottomMobileBar = () => {
    const dispatch = useDispatch();
    const {t, i18n } = useTranslation();
    const isAuth = useSelector(selectIsAuth);
    const showMobileBar = useSelector(getVisibleBottomMobileBar)
    const language = useSelector(getLocalizedLanguage)
    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }

    return <div className="bottomMobileBarMain">
        <img onClick={()=>dispatch(setBottomMobileBar(!showMobileBar))} className="hideIconFormMobileBar" src={showMobileBar ? "/img/curved-red-arrow-hide.webp": "/img/curved-red-arrow-open.webp"}/>

        <div style={{display: showMobileBar ? "block": "none"}} className="bottomMobileBarInside">
            <div className="bottomMobileBarWrapper">
                <Link to={"/"+language+"/"}><HomeOutlined className="bottomMobileBarHome"/></Link>
                <Link className="linkAsButton redButton roundedButton bottomMobileBarButton" to={"/"+language+"/panel"}>{t('Account')}</Link>
                {isAuth && <Link className="linkAsButton infoButton roundedButton bottomMobileBarButton" to={"/"+language+"/panel/results/"}>{t('Results')}</Link>}
                <Link className="linkAsButton primaryButton roundedButton bottomMobileBarButton" to={"/"+language+"/products"}>{t('Products')}</Link>
                <div>{isAuth ? <TopbarUserMobile/>  : <span className="singIn" onClick={()=>{openLoginModal()}}>{t('SignIn')}</span>}</div>
            </div>


        </div>
    </div>
}

export default BottomMobileBar;