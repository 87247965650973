import {useSearchParams} from "react-router-dom";
import {useGetMainCat4Query} from "./cat4Slice";
import Cat4ListInside from "./Cat4ListInside";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../user/settingsSlice";
import {useLocation} from "react-router";
import {useEffect} from "react";
import {setLastUrl} from "../application/applicationSlice";
import React from "react";
const Cat4List = () => {
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get("orderId");
    const dispatch = useDispatch()
    const {data, isLoading, isSuccess, isError, error} = useGetMainCat4Query({orderId: orderId})
    const isMobile = useSelector(getMobile)
    const location = useLocation();

    useEffect(()=>{
        dispatch(setLastUrl(location.pathname+location.search))
    },[])


    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])



    return <div>
        <div className="wrapper buyCard">
            <div className={isMobile ? "buyCardInsideMobile wrapperInsideMobile" : "buyCardInside"}>
                {isSuccess && <Cat4ListInside data={data} />}
            </div>
        </div>
    </div>
}

export default Cat4List;