import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useToolService from "../../hooks/useToolService";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {getLanguage} from "../../features/user/settingsSlice";
import {
    selectCurrentUser,
    selectEmail,
    selectEmailError,
    setEmail,
    setEmailError,
    setIsEmailValid
} from "../../features/auth/authSlice";

const InputEmail = ({classes}) => {
    const {isValidEmail} = useToolService()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const email = useSelector(selectEmail);
    const emailError = useSelector(selectEmailError);
    const language = useSelector(getLanguage)



    useEffect(()=>{
        emailValidate()

    },[email,language])
    const emailValidate = () => {

        if(email && email.length !== 0){
            if(!isValidEmail(email) ){
                dispatch(setEmailError(t("InvalidEmailAddress")))
                dispatch(setIsEmailValid(false))
            }
            else{
                dispatch(setEmailError(t("")))
                dispatch(setIsEmailValid(true))
            }
        }else{
            dispatch(setEmailError(""))
            dispatch(setIsEmailValid(false))
        }

    }

    return <div className="inputDivWithMessage"><input className={emailError.length>0 ? "redBorder "+classes : classes} value={email} onChange={(e)=>dispatch(setEmail(e.target.value))} type="email" placeholder="Email" /><div>{emailError}</div></div>
}

export default InputEmail;