import {useLocation, useNavigate} from "react-router";
import useToolService from "../hooks/useToolService";
import {useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage} from "../features/user/settingsSlice";
import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
const RootPageRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {allowedLanguages} = useToolService()
    const languageCode = location.pathname.substring(0,4)
    const currentLanguage = useSelector(getLocalizedLanguage)
    const language = useSelector(getLanguage)
    const [searchParams, setSearchParams] = useSearchParams();
    const success = searchParams.get("success");

    useEffect(()=>{

    },[])

    useEffect(()=>{
       // console.log(currentLanguage)
        if(success){
            if(parseInt(success) === 1){
                searchParams.delete('success');
                console.log("/"+currentLanguage+"/panel")
                navigate("/"+currentLanguage+"/panel")
            }
        }else{
            if(allowedLanguages.includes(languageCode)){

                if('/'+currentLanguage+'/' !== currentLanguage){
                    if(currentLanguage === 'en'){
                        const newPath = location.pathname.substring(4,location.pathname.length)
                        navigate(newPath+location.search)
                    }
                }
            }else{
                /*
                if(currentLanguage !== 'en'){
                    navigate(currentLanguage+location.pathname+location.search)
                }else{

                }

                 */
                navigate(currentLanguage+location.pathname+location.search)
            }
        }




    },[languageCode, currentLanguage])




    return<></>
}
export default RootPageRedirect;