import React from "react";
import {getScrollPosition, setBlackBackground} from "../features/application/applicationSlice";
import {useDispatch, useSelector} from "react-redux";
import {Close} from "@mui/icons-material";

const WhiteBackgroundForModal = (props) => {

    const dispatch = useDispatch();
    const closeHere = () => {
        props.close();
        dispatch(setBlackBackground(false))
    }
    const topPosition = useSelector(getScrollPosition);

    return <div className="modalStabilization" style={{top: (topPosition+70)+"px"}}>
        <div className="whiteBlock">
            <Close className="modalClose" onClick={()=>closeHere()}/>
           <div>
               {props.modalTitle !== undefined && <div className="modalTitle">{props.modalTitle}</div>}
               {props.modalDescription !== undefined && <div className="modalDescription">{props.modalDescription}</div>}
           </div>
            {props.children}
        </div>

    </div>

}

export default WhiteBackgroundForModal;