import {DeleteOutlined} from "@mui/icons-material";
import axios from "axios";
import {GET_FILE} from "../../../app/repository";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentToken, selectEmail, selectFirstName, selectLastName} from "../../auth/authSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {
    useUpdateEssayConversationSetCustomerFileMutation, useUpdateEssayConversationStatusMutation
} from "../../essay/essayConverstionSlice";
import {useCreateEssayMessageMutation} from "../../essay/essayMessageSlice";
import {useCreateFileMutation, useUploadEssayMutation} from "../../file/filesSlice";
import {useGetOrderMutation} from "../../order/ordersApiSlice";
import useToolService from "../../../hooks/useToolService";
import {Helmet} from "react-helmet-async";

const EssayWorkPlaceInside = ({data, price}) => {
    const MAX_FILE_SIZE = 200000 // 200MB

    const [getOrder] = useGetOrderMutation()
    const [titleEssay, setTitleEssay] = useState("")

    const orderId = data.order.id;
    const entityId = data.essay.id;

    const firstName = useSelector(selectFirstName);
    const lastName = useSelector(selectLastName);
    const email = useSelector(selectEmail);
    const [visibleButton, setVisibleButton] = useState(false)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [status, setStatus] = useState("empty")
    const [statusLoader, setStatusLoader] = useState(false)
    const [fileUploadIcon, setFileUploadIcon] = useState("/img/Circle-icons-document.png")
    const [fileSize, setFileSize] = useState(0)
    const [comment, setComment] = useState("")
    const [fileName, setFileName] = useState("")
    const [fileType, setFileType] = useState("")
    const [answerFilePath, setAnswerFilePath] = useState("#")
    const [statusFirstLineClass, setStatusFirstLineClass] = useState("");
    const [statusSecondLineClass, setStatusSecondLineClass] = useState("");
    const [firstIconUrl, setFirstIconUrl] = useState("");
    const [secondIconUrl, setSecondIconUrl] = useState("");
    const [thirdIconUrl, setThirdIconUrl] = useState("");
    const [fourthIconUrl, setFourthIconUrl] = useState("");
    const [messageCheckingClass, setMessageCheckingClass] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorFileMsg, setErrorFileMsg] = useState("");
    const [setCustomerFile] = useUpdateEssayConversationSetCustomerFileMutation()
    const [setEssayConversationStatus] = useUpdateEssayConversationStatusMutation();
    const [createEssayMessage] = useCreateEssayMessageMutation();
    const [upload] = useUploadEssayMutation()
    const [createFile] = useCreateFileMutation()
    const [correctMessage, setCorrectMessage] = useState(false)
    const [correctFile, setCorrectFile] = useState(false)
    const [answer, setAnswer] = useState(null);
    const token = useSelector(selectCurrentToken)
    const {getTranslatedValue, downloadEssay, getIcon} = useToolService();

   /* const downloadEssay = () => {

        axios.get(GET_FILE+data.id+"&type=1",{ headers: {"Authorization" : `Bearer ${token}`}, responseType: 'blob'}).then(result=>{

            const href = URL.createObjectURL(result.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', "essay_"+email.substring(0, email.indexOf('@')).replaceAll('.','_')+'.'+data.userFile.fileType); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(error=>{
            console.log(error)
        })
    }*/

    useEffect(()=>{
        switch(status){
            case "loaded":
                setStatusFirstLineClass("statusLineFirstLine waitingLine");
                setStatusSecondLineClass("statusLineFirstLine waitingLine");
                setFirstIconUrl("/img/beginLoad.png")
                setMessageCheckingClass("statusLineSecondLine waitingSpan")
                setSecondIconUrl("/img/rightArrowLoadAwait.png")
                setThirdIconUrl("/img/rightArrowLoadAwait.png")
                setFourthIconUrl("/img/finishLoadAwait.png")
                break;
            case "checking":
                setFirstIconUrl("/img/beginLoad.png")
                setStatusFirstLineClass("statusLineFirstLine doneLine");
                setSecondIconUrl("/img/rightArrowLoad.png")
                setThirdIconUrl("/img/rightArrowLoadAwait.png")
                setFourthIconUrl("/img/finishLoadAwait.png")
                setStatusSecondLineClass("statusLineFirstLine waitingLine");
                setMessageCheckingClass("statusLineSecondLine doneSpan")
                break;
            case "done":
                setStatusFirstLineClass("statusLineFirstLine doneLine");
                setStatusSecondLineClass("statusLineFirstLine doneLine");
                setThirdIconUrl("/img/rightArrowLoad.png")
                setFourthIconUrl("/img/finishLoad.png")
                setFirstIconUrl("/img/beginLoad.png")
                setMessageCheckingClass("statusLineSecondLine doneSpan")
                setSecondIconUrl("/img/rightArrowLoad.png")
                break;
            default:
                setStatusFirstLineClass("statusLineFirstLine waitingLine");
                setStatusSecondLineClass("statusLineFirstLine waitingLine");
                setFirstIconUrl("/img/beginLoadAwait.png")
                setSecondIconUrl("/img/rightArrowLoadAwait.png")
                setThirdIconUrl("/img/rightArrowLoadAwait.png")
                setFourthIconUrl("/img/finishLoadAwait.png")
                setMessageCheckingClass("statusLineSecondLine waitingSpan")
                break;
        }
    }, [status])

    useEffect(()=>{
        if(selectedFile){
            if(fileValidation(selectedFile)){
                setStatus("loaded");
            }
            else{
                setSelectedFile(null);
            }
        }
        else{
            setIcon("await")
            setStatus("await");
        }
    }, [selectedFile])

    useEffect(()=>{

        if((comment && correctMessage && !selectedFile) || (selectedFile && correctFile && !comment) || (selectedFile && correctFile && comment && correctMessage)){
                setEssayConversationStatus({id: data.id, value:"checking"})
                setStatus("checking")
        }



    }, [correctMessage, comment, selectedFile, correctFile])

    useEffect(()=>{
          /*  if(data !== undefined){
                console.log(data.status)
                if(data.userFile && !data.finished){
                    setStatus("checking");
                }
                else if(data.userFile && data.finished){
                    const message = data.messageList.filter(message=>message.user.email !== email)[0];
                    const text = message.text
                    if(text && text !== ""){
                        setAnswer(text);
                    }

                    setIcon(data.userFile.fileType)
                    setStatus("done");
                }
                else{
                    setStatus("await")
                }
            }
            else{
                setStatus("await")
            }
*/

        if(data.status === "await"){
            setStatus("await");
        }
        if(data.status === "checking"){
            setStatus("checking");
        }
        if(data.status === "done"){


            //setIcon(data.userFile.fileType)
            if(data.teacherFile){
                setIcon(data.teacherFile.fileType)
            }

            setStatus("done");
        }

    },[])
    let messagesBlock;
    if(data.status === "done"){
        const messages = data.messageList.filter(message=>!message.client);

        if(messages.length > 0){
            const sortedMessages = [...messages].sort((a,b)=>new Date(a.date) - new Date(b.date))
            messagesBlock = sortedMessages.map(((message, i)=><div key={message.id}>{message.text}{messages.length !== i+1 && <hr/>}</div>))
        }
    }

    useEffect(()=>{
        getOrder({id: Number.parseInt(orderId), value: 0}).unwrap().then((result=>{
            if(result.product.essayList.length === 0){
                navigate('/');
            } else{
                const essayIds = result.product.essayList.flatMap(essay=>essay.id);
                const entityIdN = Number.parseInt(entityId);
                if(!essayIds.includes(entityIdN)){
                    navigate('/');
                }
                else{
                    const title = result.product.essayList.filter(essay=>(essay.id === entityIdN))[0].titles.filter(title=>(title.language.title === "US"))[0].value
                    setTitleEssay(title)
                }
            }

            //setStatus("await")
        })).catch(error=>{
            console.error(error)
            navigate('/');
        })
    },[])

    const setIcon = (fileType) => {
        setFileUploadIcon(getIcon(fileType))

    }

    const fileValidation = (file) => {
        const fileSizeKiloBytes = selectedFile.size / 1024

        setFileSize(fileSizeKiloBytes)
        if(fileSizeKiloBytes){
            if(fileSizeKiloBytes > MAX_FILE_SIZE){
                setErrorFileMsg(t("FileIsGreater"));
                return false;
            }
            else{
                const fileType = file.name.split('.').at(-1);
                const fileNameString = file.name.split('.').slice(0, -1).join('.')
                setFileType(fileType)
                setIcon(fileType)
                setFileName(fileNameString)
                return true;
            }
        }


        return true;
    }

    const send = () =>{
        setStatusLoader(true);
        if(selectedFile){
            const date = new Date();
            const formattedDate = date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear();
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('essayName', titleEssay.replaceAll(" ", "_").replaceAll(".", "_").replaceAll("__", "_").toLowerCase());
            formData.append('fileType', fileType);
            formData.append('dateString', formattedDate);
            formData.append('hash', (Math.random() + 1).toString(36).substring(7));

            upload(formData)
                .unwrap()
                .then(
                    (result)=>{
                        createFile({title: titleEssay+"("+firstName+" "+email+" "+lastName+")"+" "+formattedDate, path: result.value, fileType: fileType, conversationId: data.id})
                            .unwrap()
                            .then(result=>{
                                setCustomerFile({id: data.id, value: result.id})
                                    .unwrap()
                                    .then(()=>{
                                        setCorrectFile(true) ;
                                    })
                                    .catch(error=>{
                                        setCorrectFile(false);
                                        console.error(error)
                                    })
                            }).catch(error=>{
                            setCorrectFile(false);
                            console.error(error)
                        })

                    })
                .catch(
                    error=>{
                        setCorrectFile(false);
                        console.error(error)
                    })
        }

        if(comment){
            createEssayMessage({id: data.id, value: comment}).then(()=>{
                setCorrectMessage(true);
            })
        }

        setStatusLoader(false);
    }

    const cancel = () => {
        setSelectedFile(null)
    }

    let content = "";

    if(["await", "loaded"].includes(status)){
        content = <div>
            <div className="centralBlock">
                <div
                    className="fileUploadWork"
                    onMouseEnter={()=>setVisibleButton(true)}
                    onMouseLeave={()=>setVisibleButton(false)}
                >
                    <label htmlFor="file-input" >
                        <div className="documentUpload">
                            <img src={fileUploadIcon} /><br/>
                            <div>{selectedFile ? fileName : t("Attach")  }</div>
                        </div>
                    </label>
                    <input id="file-input" type="file" accept=".odt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={
                        (e) => {
                            setSelectedFile(e.target.files[0])
                            e.target.value = null;
                        }
                    }
                    />
                    {selectedFile && <div
                        style={{display: visibleButton ? "flex" : "none"}}
                        className="buttonBefore"
                    >
                        <button onClick={()=>{cancel()}} className="cancelButton redButton">Удалить <DeleteOutlined className="white"/></button>
                    </div>}
                </div>

            </div>
            <div className="centralBlock">
                <div className="newTextarea">
                    <span className="littleH">{t("YourComment")}</span>
                    <textarea disabled={["checking", "done"].includes(status)} value={comment} onChange={(e)=>{setComment(e.target.value)}} />
                </div>
            </div>

            <div className="centralBlock">
                <button disabled={!(selectedFile || comment.length !== 0 ) || ["checking", "done"].includes(status)} onClick={()=>send()} type="button" className="redButton modalButtons flexCenterCenter">{t('Send')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                {/*
                <button disabled={!(selectedFile && comment.length !== 0 ) || ["checking", "done"].includes(status)} onClick={()=>send()} type="button" className="redButton modalButtons flexCenterCenter">{t('Send')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                */}
            </div>
        </div>;
    }


    if(status === "checking"){
        content = <div className="centralBlock">
            <div className="pleaseAwaitAnswer">

                {t("EssaySentMessage.part1")} <br/>{t("EssaySentMessage.part2")} <br/>{t("EssaySentMessage.part3")} <br/>{t("EssaySentMessage.part4")} <br/>{t("EssaySentMessage.part5")}
                <br/>
                {/*
                    <div className="cancelButtonBlock">
                    <button onClick={()=>{cancel()}} className="redButton cancelButton">{t("Cancel")} <DeleteOutlined className="white"/></button>
                </div>
                */}
            </div>
        </div>
    }
    if(status === "done"){
        content = <div className="centralBlock">
            <div className="pleaseAwaitAnswer">
                <h3>{t('Result')}</h3>

                {
                    data.teacherFile && <div className="downloadBlock" onClick={()=>{downloadEssay(data.id, "essay_"+email.substring(0, email.indexOf('@')).replaceAll('.','_')+'.'+data.teacherFile.fileType)}}>
                        <img src={fileUploadIcon} /><br/>
                        <div className="messageForIcon"><b>{t("Download")}</b></div>
                    </div>
                }
                {messagesBlock && <div className="answerBlock"> <h4>{t("TeacherAnswer")}</h4><div>{messagesBlock}</div></div>}
            </div>
        </div>
    }

    //<div className="descriptionBeforeService" dangerouslySetInnerHTML={{__html: getTranslatedValue(price.additionalDescriptions)}}/>
    return <>
        <Helmet>
            <title>{titleEssay}</title>
            <meta name='description' content={titleEssay} />
        </Helmet>
        <div className="descriptionBeforeService" dangerouslySetInnerHTML={{__html: getTranslatedValue(data.essay.instructionBeforeSending)}}/>


        <div className="centralBlock">
            <div className="statusLine">
                <div className="statusLineInside">
                    <div className="statusLineSecondLine">
                        <div className="firstLoadBlock"><img src={firstIconUrl} className="finishLoad" /></div>
                    </div>
                    <div className={statusFirstLineClass}>

                        <div className="statusLineSpace"></div>
                        <div><img src={secondIconUrl} className="rightArrowLoad" /></div>
                    </div>
                    <div className={messageCheckingClass}>
                        <span>{t("Checking")}</span>
                    </div>
                    <div className={statusSecondLineClass}>

                        <div className="statusLineSpace"></div>
                        <div><img src={thirdIconUrl} className="rightArrowLoad" /></div>
                    </div>
                    <div className="statusLineSecondLine">
                        <div><img src={fourthIconUrl} className="finishLoad" /></div>
                    </div>
                </div>

            </div>
        </div>
        {content}
    </>
}

export default EssayWorkPlaceInside;