import {useDispatch, useSelector} from "react-redux";
import {getCurrentQuestionId, setCurrentQuestionId} from "../commonTestData/testProgressLine";
import {getAnswerByQuestion, isQuestionAnswered} from "../commonTestData/testAnswersSlice";
import {getMobile} from "../user/settingsSlice";
import React from "react";
const TestNumberLineMainLineItem = ({data, i}) => {
    const isMobile = useSelector(getMobile)
    const dispatch = useDispatch()
    const num = i + 1;

 const isAnswered = useSelector((state)=>isQuestionAnswered(state, data.id))
    const currentQuestion = useSelector(getCurrentQuestionId)
    let background = "#8b8b8b";


    if(data.id === currentQuestion){
        background = "#1fa1d5";
    }
    else if(data.id !== currentQuestion && isAnswered){
        background = "#75c2e0";
    }

    return <div onClick={()=>dispatch(setCurrentQuestionId(data.id))} className={isMobile ? "testNumberLineMainItemMobile" : "testNumberLineMainItem"} key={data.id} >
        {num}
        <div className="testNumberLineMainItemIndicator" style={{backgroundColor: background}} />
    </div>
}

export default TestNumberLineMainLineItem;