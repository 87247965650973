import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    languageLevelTestResults: [],
    languageLevelCertainTestResult: null,
    sourceQuestions: []
}

const languageLevelTestSlice = createSlice({
        name: '@@languageLevelTestSlice',
        initialState: initialState,
        reducers: {
            setLanguageLevelTestResults:(state, action)=>{
                state.languageLevelTestResults = [...action.payload.slice().sort((a,b)=>a.id-b.id)];
                if(state.languageLevelTestResults.length > 0){
                    state.languageLevelResultTestResult = state.languageLevelTestResults[0]
                }
            },
            setLanguageLevelCertainTestResult:(state, action)=>{
                state.languageLevelCertainTestResult = action.payload
            },
            setSourceQuestions:(state, action)=>{
                state.sourceQuestions = action.payload
            },

        },
    }
)

export const {setSourceQuestions, setLanguageLevelTestResults, setLanguageLevelCertainTestResult} = languageLevelTestSlice.actions;
export const languageLevelTestReducer = languageLevelTestSlice.reducer;
export const getLanguageLevelTestResults = (state) => state.languageLevelTestSlice.languageLevelTestResults
export const getSourceQuestions = (state) => state.languageLevelTestSlice.sourceQuestions
export const getLanguageLevelCertainTestResult = (state) => state.languageLevelTestSlice.languageLevelCertainTestResult
