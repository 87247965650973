import TestQuestionWorkPlace from "./TestQuestionWorkPlace";

import {useDispatch, useSelector} from "react-redux";

import TestNumberLines from "./TestNumberLines";
import React, {useEffect} from "react";
import {getAnswers, setAnswers} from "../commonTestData/testAnswersSlice";
import useToolService from "../../hooks/useToolService";
import MyTimer from "../../components/MyTimer";
import {setTestStatus, setVisibleWarningCancelModal} from "../application/applicationSlice";
import {useNavigate} from "react-router";
import {getFullSeconds, setTotalTime} from "../commonTestData/timerSlice";
import MyStopwatch from "../../components/MyStopwatch";
import {getSourceQuestions} from "../languageLevelTest/languageLevelTestSlice";

const TestWorkPlace = ({data, api, url, orderId, title, time, list}) => {

const dispatch = useDispatch()
const {getTranslatedValue} = useToolService();
    const answers = useSelector(getAnswers)
    const navigate = useNavigate();
    const spentTime = useSelector(getFullSeconds)
    const source = useSelector(getSourceQuestions)
    const {resetTest} = useToolService()
    useEffect(() => {

        return () => {
            dispatch(setAnswers([]))
        }
    }, []);

    useEffect(() => {

        dispatch(setTotalTime(data.time))
    }, [data]);

    const save = async () => {
        if(answers.length  > 0){
            try {
                const realTime = data.time > 0 ? (data.time - spentTime) : spentTime;
                dispatch(setTestStatus("loading"))
                const result = await api({body: {spentTime: realTime, totalTime: data.time, answers, source}, orderId: orderId}).unwrap();
                dispatch(setTestStatus("await"))
                resetTest()
                navigate(url+result)

            } catch (err) {
                console.log(err)
            }
        }
        else{
            resetTest()
            dispatch(setTestStatus("await"))
        }

    }



    return  <>

        <div className="fakeBackground" >
        <div className="testTitleTop wrapper2">{title}</div>
        <div className="wrapper2 buyCard">
            <div className="buyCardInside2 boxShadow">
                <div className="timerPlace">
                    {
                        time === 0 ? <MyStopwatch time={3600}  d={false} h={true} m={true} s={true} callback={save}  /> : <MyTimer time={time} d={false} h={false} m={true} s={true} callback={save}  />
                    }

                </div>
                {
                    list.map((question, index)=>{

                    return <TestQuestionWorkPlace api={api} url={url} key={question.id} question={question} nextQuestion={list[index+1]} previousQuestion={list[index-1]} />
                })}

                    {
                   // <div className="questionBlock"><TestQuestionWorkPlace question={questions[currentSelected]} /><BottomQuestionNavigation /></div>
                    }


            </div>

        </div>
        <div className="wrapper2">
            <TestNumberLines data={list} />
        </div>

    </div></>
}

export default TestWorkPlace;