import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getCountry, getCurrentTitle, getLocalizedLanguage, getMobile} from "../../common/features/user/settingsSlice";
import {useTranslation} from "react-i18next";
import {
    selectEmail,
    selectFirstName,
    selectIsAuth,
    selectIsEmailValid, selectIsFirstnameValid, selectIsLastnameValid,
    selectLastName
} from "../../common/features/auth/authSlice";
import InputFirstname from "../../common/components/UI/InputFirstname";
import InputLastname from "../../common/components/UI/InputLastname";
import InputEmail from "../../common/components/UI/InputEmail";

import {useGetAManagerQuery} from "../../common/features/user/userApiSlice";
import {useCreateUserRequestMutation} from "../../common/features/userRequest/userRequestsSlice";
import {
    setBlackBackground,
    setVisibleErrorModal,
    setVisibleSuccessModal
} from "../../common/features/application/applicationSlice";

const Footer = () => {
    const isMobile = useSelector(getMobile)
    const isAuth = useSelector(selectIsAuth)
    const language = useSelector(getLocalizedLanguage)
    const {
        data,
        isLoading,
        isSuccess: isSuccessManager,
        isError: isErrorManager
    } = useGetAManagerQuery();
    const email = useSelector(selectEmail);
    const [statusLoader, setStatusLoader] = useState(false)
    const firstname = useSelector(selectFirstName);
    const lastname = useSelector(selectLastName);
    const [statusSend, setStatusSend] = useState(false)
    const [isError, setIsError] = useState(false)
    const isEmailValid = useSelector(selectIsEmailValid)
    const isFirstnameValid = useSelector(selectIsFirstnameValid)
    const isLastNameValid = useSelector(selectIsLastnameValid)
    const [createRequest] = useCreateUserRequestMutation()
    const currentTitle = useSelector(getCurrentTitle)
    const country = useSelector(getCountry)
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const send = () => {
          if(isEmailValid && isFirstnameValid && isLastNameValid && isSuccessManager){

            const object = {path: window.location.pathname, text: text, email: email, firstname: firstname, lastname: lastname, auth: isAuth, manager: data.email, page: currentTitle, country: country.country_name}
            createRequest(object).then(()=>{
                 dispatch(setVisibleSuccessModal(true))
                 dispatch(setBlackBackground(true))
            }).catch(()=>{
                dispatch(setVisibleErrorModal(true))
                dispatch(setBlackBackground(true))
            })
        }


    }
    const { t } = useTranslation();
    return <div>
        <div className={isMobile ? "wrapperInsideMobile" : "wrapper classicalFlex"}>
            <div></div>
            <div className={isMobile ? "bottomAskMobile" : "bottomAsk"}>
                <div>
                    <div>
                        <div className="bottomAskHeader">{t('AnyQuestions')}</div>
                    </div>
                    <div className="formInputs">
                        <div className={!isMobile ? "bottomAskTwoParts":""}>
                        {!isAuth && <div>
                        <InputFirstname classes="modalInput"/>
                        <InputLastname classes="modalInput"/>
                        <InputEmail classes="modalInput"/>
                    </div>
                    }<div>
                        <div className="modalTextArea">
                   <textarea className="modalInput" value={text} onChange={(e)=>setText(e.target.value)} placeholder={t("YourMessage")}>

            </textarea>
                        </div>

                        <button onClick={()=>send()} type="button" className="redButton modalButtons flexCenterCenter modalInput">{t('Send')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                        </div>
                    </div></div>
                </div>
                {!isMobile && <div><img src={"/img/any-questions-picture-2.webp"} alt={"Ask us"} /></div>}
            </div>

        </div>
        <div className={isMobile ? "wrapperInsideMobile" : "wrapper classicalFlex"}>
            <div>
                <div className="footerLogo">
                    <div className="logoBlock">
                        <img className="logoFooter" alt={"Many Tests Logo"} src="/img/logo2.webp" />
                    </div>
                    <div className="contactLink">
                        <Link to={"/"+language+"/contact-us"}>{t("Contacts")}</Link>
                        <a href={language === "ru" ? "/privacy-policy-ru" : "/privacy-policy-en"}>Private Policy</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default Footer;