import {BallTriangle} from "react-loader-spinner";
import React from "react";
const BallLoader = () => {

    return <BallTriangle
        height={80}
        width={80}
        radius={5}
        color="#e54b42"
        ariaLabel="ball-triangle-loading"
        wrapperClass={"centralLoader"}
        wrapperStyle=""
        visible={true}
    />
}

export default BallLoader;