import {addAnswer, getAnswerByQuestion} from "../commonTestData/testAnswersSlice";
import {useDispatch, useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import React from "react";
import useToolService from "../../hooks/useToolService";
const OneSideOptions = ({options, questionId, side, multiplicity}) => {

    const {checkAnswerInsideAnswers} = useToolService()
    const isMobile = useSelector(getMobile)
    const dispatch = useDispatch()
    let startPosition;
    if(side === "LEFT"){
        startPosition = 65;
    }
    else{
        startPosition = 88;
    }

    return <div className={isMobile ? "questionAnswersTextSideMobile":"questionAnswersTextSide"}>
        {

            options.map((option,index)=>{

                return <div key={option.id} className={checkAnswerInsideAnswers(questionId).includes(option.id) ? "textOption selectedOption" : "textOption"} onClick={()=>{

                    dispatch(addAnswer({idTest: questionId, idAnswer: option.id, side: side, multiplicity: multiplicity}))}
                }>
                    <span>{String.fromCharCode(index+startPosition)}:</span>
                    <div  >
                        <div dangerouslySetInnerHTML={{__html: option.title}}/>
                    </div>

                </div>
            })}
    </div>
}

export default OneSideOptions;