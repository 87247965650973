import {Link} from "react-router-dom";

import PriceBlockForMainProduct from "./PriceBlockForMainProduct";
import useToolService from "../../hooks/useToolService";

import {useSelector} from "react-redux";

import {getLanguage, getLocalizedLanguage, getMobile} from "../user/settingsSlice";

import React from "react";

const MainProductItem = ({item, dateTime}) => {
    const isMobile = useSelector(getMobile)
    const {getTranslatedValue} = useToolService();
    const sortedPrices = [...item.prices].sort((a,b)=>a.value-b.value);
    const firstElement = sortedPrices[0];
    const filteredPrices = [...sortedPrices].splice(1).filter((price)=>price.globalSale);
    const pricesBlock = filteredPrices.map((price=><PriceBlockForMainProduct dateTime={dateTime} key={price.id} item={price} first={false}/>))
    const language = useSelector(getLocalizedLanguage)
    let classes="testListButton testListButtonDesktop";
    if(isMobile){
        classes="testListButton testListButtonMobile";
    }

    return <Link className={classes} to={"/"+language+"/products/"+item.alias}>
        <div style={{backgroundImage: "url(\""+item.picture+"\")"}}></div>
        <div className="testListTitle">{getTranslatedValue(item.titles)}</div>
        <div><PriceBlockForMainProduct dateTime={dateTime} item={firstElement} first={true} />
        {pricesBlock}
        </div>

</Link>
}

export default MainProductItem;