import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Logout, Person} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {
    logout,
    selectAvatar,
    selectCurrentUser,
    selectFirstName,
    selectLastName
} from "../../common/features/auth/authSlice";
import {useTranslation} from "react-i18next";
import {getLanguage, getLocalizedLanguage} from "../../common/features/user/settingsSlice";

const TopbarUser = () => {

    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch();
    const {t } = useTranslation();
    const firstName = useSelector(selectFirstName);
    const lastName = useSelector(selectLastName);
    const avatar = useSelector(selectAvatar);
    const language = useSelector(getLocalizedLanguage)

    return <div className="topbarUser" aria-expanded={visible} onClick={()=>{setVisible(!visible)}}>
        <div className="topBarImageAvatar" style={{backgroundImage: "url("+avatar+")"}}></div>
        <div>
            {firstName} {lastName}
        </div>
        <div className="topUserMenu" style={{display: visible ? "block" : "none"}}>
            <ul>
                <li><Link to={"/"+language+"/panel/profile"}><span className="linkTopUser"><span><Person style={{color:  "#084764"}} /></span><span>{t("Profile")}</span></span></Link></li>
                <li onClick={()=>{dispatch(logout());window.location.reload();}}><span className="linkTopUser"><span><Logout style={{color:  "#e54b42"}} /></span><span>{t("Exit")}</span></span></li>
            </ul>
        </div>
    </div>
}

export default TopbarUser;