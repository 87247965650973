import React from "react";
import {useSelector} from "react-redux";
import {getBlackBackground} from "../features/application/applicationSlice";

const BackgroundForModal = () => {
    const blackBackground = useSelector(getBlackBackground)

    return <div>
        <div className="blackBackground" style={{display: blackBackground ? "block" : "none"}}></div>

    </div>

}

export default BackgroundForModal;