import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../features/user/settingsSlice";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {
    selectConfirmPassword,
    selectConfirmPasswordError,
    selectPassword,
    setConfirmPassword,
    setConfirmPasswordError,
    setIsConfirmPasswordValid,

} from "../../features/auth/authSlice";
import useToolService from "../../hooks/useToolService";

const InputConfirmPassword = ({classes}) => {
    const dispatch = useDispatch();
    const confirmPassword = useSelector(selectConfirmPassword);
    const password = useSelector(selectPassword);
    const confirmPasswordError = useSelector(selectConfirmPasswordError);
    const language = useSelector(getLanguage)
    const {isTooBig} = useToolService()
    const { t } = useTranslation();

    const passwordValidate = () => {
        if(confirmPassword && confirmPassword.length !== 0) {
            if (confirmPassword !== password) {
                dispatch(setConfirmPasswordError(t("ConfirmPasswordError")))
                dispatch(setIsConfirmPasswordValid(false))
            } else if (isTooBig(confirmPassword)) {
                dispatch(setConfirmPasswordError(t("TooBig")))
                dispatch(setIsConfirmPasswordValid(false))
            } else {
                dispatch(setConfirmPasswordError(""))
                dispatch(setIsConfirmPasswordValid(true))
            }
        }else{
            dispatch(setConfirmPasswordError(""))
            dispatch(setIsConfirmPasswordValid(false))
        }
    }

    useEffect(()=>{
        passwordValidate()
    },[confirmPassword,language])

    return <div className="inputDivWithMessage">
        <input
            className={confirmPasswordError.length>0 ? "redBorder "+classes : classes}
            type="password"
            value={confirmPassword}
            placeholder={t('ConfirmPassword')}
            onChange={(e)=>dispatch(setConfirmPassword(e.target.value))}
        />
        <div>{confirmPasswordError}</div>
    </div>
}

export default InputConfirmPassword;