import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    offerAccepted: false,
    offerLink: "/offer-en.pdf"

}


const offerCheckboxBlockSlice = createSlice({
        name: '@@offerCheckboxBlockSlice',
        initialState: initialState,
        reducers: {
            setOfferAccepted:(state, action)=>{
                state.offerAccepted = action.payload;
            },
            setOfferLink:(state, action)=>{
                state.offerLink = action.payload
            },
        },
    }
)

export const {setOfferAccepted, setOfferLink} = offerCheckboxBlockSlice.actions;
export const offerCheckboxBlockReducer = offerCheckboxBlockSlice.reducer;
export const getOfferAccepted = (state) => state.offerCheckboxBlockSlice.offerAccepted;
export const getOfferLink = (state) => state.offerCheckboxBlockSlice.offerLink;