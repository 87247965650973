import {apiSlice} from "../../app/api/apiSlice";
import {
    CHECK_PAID_PRODUCT_BY_ID,
    GET_ALL_PRODUCTS,
    GET_PRODUCT_BY_ALIAS,
    GET_PRODUCT_BY_ID
} from "../../app/repository";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import RootAddress from "../../../API/RootAddress";

export const productsSlice =  createApi({
    reducerPath: 'productsSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: RootAddress.getURL,
    }),
    tagTypes: ['Products'],
    endpoints: builder => ({
        getAllProducts: builder.query({
            query: () => GET_ALL_PRODUCTS,
            providesTags: (result = [], error, arg) => [
                'Products',
                ...result.map(({ id }) => ({ type: 'Products', id }))
            ],
        }),

        getProductById: builder.query({
            query: id =>  GET_PRODUCT_BY_ID+id,
            providesTags: (result, error, arg) => [{ type: 'Products', id: arg }]

        }),
        getProductByAlias: builder.query({
            query: alias =>  GET_PRODUCT_BY_ALIAS+alias,
            providesTags: (result, error, arg) => [{ type: 'Products', id: arg }]

        }),

    })
})

export const {
    useGetProductByAliasQuery,
    useGetProductByIdQuery,
    useGetAllProductsQuery
} = productsSlice