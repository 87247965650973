import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLocalizedLanguage, getMobile, setGlobalLoader} from "../features/user/settingsSlice";
import {useTranslation} from "react-i18next";


const ContactUs = () => {
    const {t} = useTranslation()
    const isMobile = useSelector(getMobile)
    const language = useSelector(getLocalizedLanguage)
    return <div className="articleBlock">
        <Helmet>
            <title>{t("Contacts")}</title>
            <meta name='description' content={t("Contacts")} />
        </Helmet>
        <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide">
                    <div className="pageNotFound">
                        <h1>{t("Contacts")}</h1>
                        <br/><br/>

                        <div className="legalInformation">
                            {
                                language === "ru" ?
                                    <div>
                                        <p>ИП Мельник Елена Викторовна</p>
                                        <p>ОГРН/ОГРНИП: 322774600612371</p>
                                        <p>Email: <a href="mailto:support@many-tests.com">support@many-tests.com</a></p>
                                    </div> :
                                    <div>
                                        <p>IE Elena Viktorovna Melnik</p>
                                        <p>PSRN/PSRNIP: 322774600612371</p>
                                        <p>Email: <a href="mailto:support@many-tests.com">support@many-tests.com</a></p>
                                    </div>
                            }
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
}

export default ContactUs;