import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    user: null,
    error: null,
    token: null,
    firstName: "",
    avatar: "/img/avatars/none.webp",
    lastName: "",
    phone: "",
    phoneError: "",
    email: "",
    activity: false,
    refreshToken: "",
    roles: [],
    emailError: "",
    password: "",
    passwordError: "",
    isPasswordValid: false,
    confirmPassword: "",
    confirmPasswordError: "",
    confirmIsPasswordValid: false,
    firstnameError: "",
    lastnameError: "",
    isEmailValid: false,
    isLastnameValid: false
}

export const authSlice = createSlice({
    name: "@@user",
    initialState,
    reducers:{
        logout: (state, _) => {
            state.phone = "";
            state.roles = [];
            state.activity = false;
            state.avatar = "/img/avatars/none.webp";
            state.firstName = "";
            state.email = "";
            state.lastName = "";
            state.token = "";
            state.refreshToken = "";
            state.isAuth = false;

        },
        setCredentials: (state, action) => {
            state.phone = action.payload.userFields.phoneNumber;
            state.roles = action.payload.roles;
            state.activity = action.payload.activity;
            state.avatar = action.payload.avatar;
            state.firstName = action.payload.firstName;
            state.email = action.payload.email;
            state.lastName = action.payload.lastName;
            state.token = action.payload.jwt;
            state.refreshToken = action.payload.refreshToken.token;
            state.isAuth = true;
        },
        setLastname: (state, action) => {
            state.lastName = action.payload;
        },
        setPhoneError: (state, action) => {
            state.phoneError = action.payload;
        },
        setFirstname: (state, action) => {
            state.firstName = action.payload;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setAvatar: (state, action) => {
            state.avatar = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword:(state, action)=>{
            state.password = action.payload;
        },
        setConfirmPassword:(state, action)=>{
            state.confirmPassword = action.payload;
        },
        setConfirmPasswordError:(state, action)=>{
            state.confirmPasswordError = action.payload;
        },
        setPasswordError:(state, action)=>{
            state.passwordError = action.payload;
        },
        setEmailError:(state, action)=>{
            state.emailError = action.payload;
        },
        setFirstnameError:(state, action)=>{
            state.firstnameError = action.payload;
        },
        setLastnameError:(state, action)=>{
            state.lastnameError = action.payload;
        },
        setIsEmailValid:(state, action)=>{
            state.isEmailValid = action.payload;
        },
        setIsLastnameValid:(state, action)=>{
            state.isLastnameValid = action.payload;
        },
        setIsFirstnameValid:(state, action)=>{
            state.isFirstnameValid = action.payload;
        },
        setIsPasswordValid:(state, action)=>{
            state.isPasswordValid = action.payload;
        },
        setIsConfirmPasswordValid:(state, action)=>{
            state.isConfirmPasswordValid = action.payload;
        },

    },

})

export const authReducer = authSlice.reducer;

export const {setConfirmPassword, setConfirmPasswordError, setIsConfirmPasswordValid, setEmail, setPhoneError, setLastnameError, setFirstnameError, setIsFirstnameValid, setIsLastnameValid, logout, setCredentials, setIsPasswordValid, setIsEmailValid, setEmailError, setPasswordError, setPassword, setAvatar,setPhone, setFirstname, setLastname  } = authSlice.actions;

export const selectCurrentUser = (state) => state.auth.user;
export const selectIsAuth = (state) => state.auth.isAuth;
export const selectPhone = (state) => state.auth.phone;
export const selectPhoneError = (state) => state.auth.phoneError;
export const selectRoles = (state) => state.auth.roles;
export const selectActivity = (state) => state.auth.activity;
export const selectAvatar = (state) => state.auth.avatar;
export const selectEmail = (state) => state.auth.email;
export const selectPassword = (state) => state.auth.password;
export const selectConfirmPassword = (state) => state.auth.confirmPassword;
export const selectFirstName = (state) => state.auth.firstName;
export const selectLastName = (state) => state.auth.lastName;
export const selectLastNameError = (state) => state.auth.lastnameError;
export const selectFirstNameError = (state) => state.auth.firstnameError;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectCurrentToken = (state) => state.auth.token;
export const selectEmailError = (state) => state.auth.emailError;
export const selectPasswordError = (state) => state.auth.passwordError;
export const selectConfirmPasswordError = (state) => state.auth.confirmPasswordError;
export const selectIsPasswordValid = (state) => state.auth.isPasswordValid;
export const selectIsConfirmPasswordValid = (state) => state.auth.isConfirmPasswordValid;
export const selectIsEmailValid = (state) => state.auth.isEmailValid;
export const selectIsLastnameValid = (state) => state.auth.isEmailValid;
export const selectIsFirstnameValid = (state) => state.auth.isFirstnameValid;
