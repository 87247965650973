import {useDispatch, useSelector} from "react-redux";
import {addAnswer, getAnswerByQuestion, getAnswers} from "../commonTestData/testAnswersSlice";
import {getCurrentQuestionId} from "../commonTestData/testProgressLine";
import BottomQuestionNavigation from "./BottomQuestionNavigation";
import {useEffect, useState} from "react";
import TwoSideOptions from "./TwoSideOptions";
import OneSideOptions from "./OneSideOptions";
import PictureOptions from "./PictureOptions";
import {getMobile} from "../user/settingsSlice";
import React from "react";
const TestQuestionWorkPlace = ({question, nextQuestion, previousQuestion, url, api}) => {
    const [isTextField, setIsTextField] = useState(false)
    const isMobile = useSelector(getMobile)
    const currentSelected = useSelector(getCurrentQuestionId)
    const [options, setOptions] = useState([])

    const shuffle = (array) => {
        return [...array].sort(() => Math.random() - 0.5);
    };
    useEffect(()=>{
        setIsTextField(question.options[0].testFieldType === "TEXT")
        setOptions(shuffle(question.options))
    },[question])

    let content;



    switch(question.answerView){
        case "TEXT_TWO_PARTED":
            content = <TwoSideOptions multiplicity={question.answerMultiplicity} options={options} questionId={question.id} />
            break;
        case "TEXT_SINGLE_PART":
            content = <OneSideOptions multiplicity={question.answerMultiplicity} options={options} questionId={question.id} side="LEFT"/>
            break;
        case "PICTURE":
            content = <PictureOptions multiplicity={question.answerMultiplicity} options={options} questionId={question.id} side="LEFT"/>
            break;
        default:
            content = <OneSideOptions multiplicity={question.answerMultiplicity} options={options} questionId={question.id} side="LEFT"/>
    }

    return <div className="questionBlock" style={{display: currentSelected === question.id ? "block" : "none"}}>
            <div className="questionTitle" dangerouslySetInnerHTML={{__html:question.title}}/><hr/>
            <div className={isMobile ? "questionDescriptionSide questionDescriptionSideMobile" : "questionDescriptionSide"} dangerouslySetInnerHTML={{__html:question.description}}/>
            <div className="questionDivider" />
            {content}
            <BottomQuestionNavigation api={api} url={url} nextQuestion={nextQuestion} previousQuestion={previousQuestion} />
        </div>

}

export default TestQuestionWorkPlace;