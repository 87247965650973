import {apiSlice} from "../../app/api/apiSlice";
import {ESSAY_CONVERSATION_UPDATE_CUSTOMER_FILE, GET_CHECK_BY_ID, UPDATE_CHECK_PAID} from "../../app/repository";
import {ordersApiSlice} from "../order/ordersApiSlice";

export const checksSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCheckById: builder.query({
            query: id =>  GET_CHECK_BY_ID+id,
            providesTags: (result, error, arg) => [{ type: 'Orders', id: arg }]

        }),
        updateCheckPaid: builder.mutation({
            query: (formData) => {
                return {
                    url: UPDATE_CHECK_PAID,
                    method: 'PATCH',
                    body: formData,
                }

            },
            invalidatesTags:  ['Orders', 'EssayConversations']
        }),
    })
})

export const {
    useGetCheckByIdQuery,
    useUpdateCheckPaidMutation
} = checksSlice
