import {useGetMainCat4Query} from "./cat4Slice";
import useToolService from "../../hooks/useToolService";
import {useTranslation} from "react-i18next";
import {Link, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import Cat4HiddenBigBlock from "./Cat4HiddenBigBlock";
import {Helmet} from "react-helmet-async";
import React from "react";
const Cat4ListInside = ({data}) => {
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get("orderId");
    const isMobile = useSelector(getMobile)
    const {getTranslatedValue} = useToolService();
    const { t } = useTranslation();

    let boxContent = "box-content";
    let linkInsideAccordion = "linkInsideAccordion";
    if(isMobile){
        linkInsideAccordion = "linkInsideAccordionMobile"
        boxContent = "box-content box-content-mobile";
    }

    const groupByPack = data.reduce((group, arr) => {
            const { mainCategoryTest } = arr;
            group[mainCategoryTest.sample.pack.title] = group[mainCategoryTest.sample.pack.title] ?? [];
            group[mainCategoryTest.sample.pack.title].push(arr);
            return group;
        },

        {});


    const groupBySample = (arr) => {
        return arr.reduce((group, ar) => {
                const { mainCategoryTest } = ar;
                group[mainCategoryTest.sample.title] = group[mainCategoryTest.sample.title] ?? [];
                group[mainCategoryTest.sample.title].push(ar);
                return group;
            },

            {});

    }
    const groupByMainCategory = (arr) => {
        return arr.reduce((group, ar) => {
                const { mainCategoryTest } = ar;
                group[mainCategoryTest.title] = group[mainCategoryTest.title] ?? [];
                group[mainCategoryTest.title].push(ar);
                return group;
            },

            {});

    }

    for (const key in groupByPack) {
        groupByPack[key] = groupBySample(groupByPack[key])

    }
    for (const key in groupByPack) {
        const inside = groupByPack[key];
        for (const key2 in inside) {
            inside[key2] = groupByMainCategory(inside[key2])
        }
    }

    const content2 = Object.keys(groupByPack).sort().map((key, index) => {

        return(
            <div key={key}>
                <h2>{key}</h2>
                {Object.keys(groupByPack[key]).map((key2, index2) => {

                    return <Cat4HiddenBigBlock
                        groupByPack={groupByPack}
                        key={index2}
                        key1={key}
                        key2={key2}
                        boxContent={boxContent}
                        linkInsideAccordion={linkInsideAccordion}
                        orderId={orderId} />
                })
                }
            </div>

        )
    })



    return <div>
        <Helmet>
            <title>
                CAT4
                {
                //getTranslatedValue(data.titles)+" | "+data.category+" | "+data.sample+" | "+data.pack : "Many Tests"
                 }
               </title>
            <meta name='description' content="Panel" />
        </Helmet>
        {content2}


    </div>
}

export default Cat4ListInside;