import {useParams} from "react-router-dom";
import {useGetArticleByAliasQuery} from "./articleSlice";
import useToolService from "../../hooks/useToolService";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../user/settingsSlice";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";

const ArticleDetailed = () => {
    const {getTranslatedValue, preparingUrlForNavigation, getLanguageLinksForPages, prepareMeta} = useToolService();
    const {alias} = useParams();
    const isMobile = useSelector(getMobile)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetArticleByAliasQuery(alias);

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    let content;
    let title;
    let description;

    if(isSuccess){
        content = getTranslatedValue(data.body);
        title = getTranslatedValue(data.titles);
        description = getTranslatedValue(data.descriptions);

    }

    const contentClickHandler = (e) => {
         navigate(preparingUrlForNavigation(e));
    };

    return   <div className="articleBlock">
        <Helmet
            link={getLanguageLinksForPages("/articles/"+alias)}
            meta={prepareMeta(title, description, "article", "articles/"+alias, "")}
            title={title}
        />




        <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide">
                    <h1>{title}</h1>
                    <div onClick={contentClickHandler} dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
        </div>
    </div>
}

export default ArticleDetailed;