import {useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import {addAnswer} from "../commonTestData/testAnswersSlice";
import {CheckCircle, Dangerous} from "@mui/icons-material";
import React from "react";

const OneSideOptionResult = ({options}) => {
    const isMobile = useSelector(getMobile)


    return <div className={isMobile ? "questionAnswersTextSideMobile":"questionAnswersTextSide"}>
        {

            options.map((option,index)=>{

                let resultClassBlock;
                let icon;
                if(option.correct){
                    resultClassBlock = "textOptionResultCorrect textOptionResult";
                    icon = <CheckCircle className="resultIconPicture correctIcon" />
                }else if(option.answered && !option.correct){
                    resultClassBlock = "textOptionResult textOptionResultWrong";
                    icon = <Dangerous className="resultIconPicture wrongIcon"/>
                }
                else{
                    resultClassBlock = "textOptionResult";
                    icon = <span className="resultIconPictureEmpty"></span>
                }

                let startPosition;
                if(option.horizontalPosition === "LEFT"){
                    startPosition = 65;
                }
                else{
                    startPosition = 88;
                }

                return <div key={index} className="textOptionResultMain">
                    <div  className={resultClassBlock} >
                        <span>{String.fromCharCode(index+startPosition)}:</span>
                        <div  >
                            <div dangerouslySetInnerHTML={{__html: option.title}}/>
                        </div>

                    </div>
                    {
                        icon
                    }
                </div>
            })}
    </div>
}

export default OneSideOptionResult;