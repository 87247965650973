import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useCheckQuery} from "../features/auth/authApiSlice";
import {useTranslation} from "react-i18next";
import {setCountry, setCurrencies, setLanguage} from "../features/user/settingsSlice";
import {useGetCurrenciesQuery, useGetDefaultCountryQuery} from "../features/user/settingsApiSlice";
import {selectIsAuth} from "../features/auth/authSlice";


export const useInitialization = () => {
    const {i18n } = useTranslation();
    const dispatch = useDispatch();
    const isAuth = useSelector(selectIsAuth);
/*
    useEffect(()=>{
        let language;


        if(i18n.language.toUpperCase() === "EN-US"){

            language = "US"
        }else{
            language = i18n.language.toUpperCase()
        }
        console.log(language)
        dispatch(setLanguage(language))



    },[])
*/

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useCheckQuery();

    const {
        data: dataCountry,
        isLoading: isLoadingCountry,
        isSuccess: isSuccessCountry,
        isError: isErrorCountry,
        error: errorCountry
    } = useGetDefaultCountryQuery()


    const {
        data: dataCurrency,
        isLoading: isLoadingCurrency,
        isSuccess: isSuccessCurrency,
        isError: isErrorCurrency,
        error: errorCurrency
    } = useGetCurrenciesQuery()


    useEffect(()=>{

        if(isSuccessCountry){

            dispatch(setCountry(dataCountry))
        }
    }, [isSuccessCountry])

    useEffect(()=>{

        if(isSuccessCurrency){

            const currencyInformation = {
                RUB: {
                    position: 5,
                    symbol: "₽"
                },
                USD:{
                    position: 1,
                    symbol: "$"
                },
                KZT:{
                    position: 4,
                    symbol: "₸"
                },
                CNY:{
                    position: 3,
                    symbol: "¥"
                },
                EUR: {
                    position: 2,
                    symbol: "€"
                },
            }


            const currencies = dataCurrency.ValCurs.Valute.filter((valute)=>["USD", "KZT", "CNY", "EUR"].includes(valute.CharCode)).map(currency=>{
                const currentCurrencyInformationItem = currencyInformation[currency.CharCode];

                return {...currency, Symbol: currentCurrencyInformationItem.symbol, Position: currentCurrencyInformationItem.position}
            }).sort((a,b)=>a.Position - b.Position)

           dispatch(setCurrencies(currencies))
        }
    }, [isSuccessCurrency])

  /*  useEffect(()=>{
        if(isAuth){

        }
    },[isAuth])
*/



}