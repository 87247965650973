import {apiSlice} from "../../app/api/apiSlice";
import {
    CREATE_ESSAY_CONVERSATION, ESSAY_CONVERSATION_UPDATE_CUSTOMER_FILE, ESSAY_CONVERSATION_UPDATE_STATUS,
    GET_ALL_ESSAY_CONVERSATION_FINISHED,
    GET_ESSAY_CONVERSATION,
    GET_MY_ORDERS, UPDATE_ESSAY_CONVERSATION_SET_ORDER
} from "../../app/repository";

export const essayConversationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEssayConversation: builder.query({
            query: ({entityId,orderId}) => {

                return GET_ESSAY_CONVERSATION+"?entityId="+entityId+"&"+"orderId="+orderId},
            providesTags: (result, error, arg) => [{ type: 'EssayConversations', id: arg }]

        }),
        createEssayConversation: builder.mutation({
            query: (formData) => {
                return {
                    url: CREATE_ESSAY_CONVERSATION,
                    method: 'POST',
                    body: formData,
                }

            }
        }),
        updateEssayConversationSetOrder: builder.mutation({
            query: (formData) => {
                return {
                    url: UPDATE_ESSAY_CONVERSATION_SET_ORDER,
                    method: 'POST',
                    body: formData,
                }

            }
        }),
        updateEssayConversationSetCustomerFile: builder.mutation({
            query: (formData) => {
                return {
                    url: ESSAY_CONVERSATION_UPDATE_CUSTOMER_FILE,
                    method: 'PATCH',
                    body: formData,
                }

            }
        }),
        updateEssayConversationStatus: builder.mutation({
            query: (formData) => {
                return {
                    url: ESSAY_CONVERSATION_UPDATE_STATUS,
                    method: 'PATCH',
                    body: formData,
                }

            },
            invalidatesTags:  ['Orders', 'EssayConversations']
        }),
        //
      /*  updateEssayConversationSetEssayFile: builder.mutation({
            query: (formData) => {
                return {
                    url: UPDATE_ESSAY_CONVERSATION_SET_ESSAY_FILE,
                    method: 'POST',
                    body: formData,
                }

            }
        }), */
        getAllEssayConversationsFinished: builder.query({
            query: () => GET_ALL_ESSAY_CONVERSATION_FINISHED,
            providesTags: (result = [], error, arg) => [
                'EssayConversations',
                ...result.map(({ id }) => ({ type: 'EssayConversations', id }))
            ],

        }),
    })
})

export const {
    useGetEssayConversationQuery,
    useCreateEssayConversationMutation,
    useGetAllEssayConversationsFinishedQuery,
    useUpdateEssayConversationSetCustomerFileMutation,
    useUpdateEssayConversationStatusMutation

} = essayConversationSlice