import React from "react";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";
import {

    getVisibleNewPasswordModal, setVisibleNewPasswordModal,

} from "../features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import InputEmail from "./UI/InputEmail";
import useToolService from "../hooks/useToolService";
import SuccessMessageModal from "./SuccessMessageModal";
import {useEffect, useState} from "react";
import {
    useConfirmationMutation, useHardPasswordResetMutation,
    useNewPasswordReset,
    useNewPasswordResetMutation,
    useResetMutation
} from "../features/auth/authApiSlice";
import InputPassword from "./UI/InputPassword";
import {getLanguage} from "../features/user/settingsSlice";
import {CheckCircle} from "@mui/icons-material";
import {
    selectEmail,
    selectIsPasswordValid,
    selectPassword,
    setIsPasswordValid,
    setPasswordError
} from "../features/auth/authSlice";

const NewPasswordModal = () => {
    const dispatch = useDispatch();
    const [statusLoader, setStatusLoader] = useState(false)
    const visibleNewPasswordModal = useSelector(getVisibleNewPasswordModal);
    const password = useSelector(selectPassword);
    const isPasswordValid = useSelector(selectIsPasswordValid)
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [successReset, setSuccessReset] = useState(false);
    const language = useSelector(getLanguage)
    const { t } = useTranslation();

    const {isTooBig, loginHandler} = useToolService()
    const [newPassword] = useNewPasswordResetMutation();

    const params = new URLSearchParams(window.location.search);
    const resetPasswordToken = params.get("resetPassword");

    const [hardPasswordReset] = useHardPasswordResetMutation();

    useEffect(()=>{
        if(confirmPassword.length !== 0) {
            if (confirmPassword !== password) {
                setConfirmPasswordError(t("ConfirmPasswordError"))
                setConfirmPasswordValid(false)
            } else if (isTooBig(confirmPassword)) {
                setConfirmPasswordError(t("TooBig"))
                setConfirmPasswordValid(false)
            } else {
                setConfirmPasswordError("")
                setConfirmPasswordValid(true)
            }
        }else{
            setConfirmPasswordError("")
            setConfirmPasswordValid(false)
        }
    },[confirmPassword, language])


    useEffect(()=>{
        if(resetPasswordToken && resetPasswordToken.length !== 0){
            hardPasswordReset(resetPasswordToken)
        }
    }, [])

    const saveNewPassword = () => {
        if(password.length === 0){
            dispatch(setPasswordError(t("InvalidEmptyField")))
            dispatch(setIsPasswordValid(false))
        }
        if(confirmPassword.length === 0){
            setConfirmPasswordError(t("InvalidEmptyField"))
            setConfirmPasswordValid(false)
        }

        if(isPasswordValid && isConfirmPasswordValid){
            setStatusLoader(true);
            newPassword({email: resetPasswordToken, password: password}).then(()=>{
                setSuccessReset(true);
                setStatusLoader(false)
            }).catch((error)=>{
                if(error.status){
                    dispatch(setPasswordError(t("UserNoExist")))
                    dispatch(setIsPasswordValid(false))
                }
            })


        }
    }

    return <div style={{display: visibleNewPasswordModal ? "block" : "none"}}>
        <WhiteBackgroundForModal modalTitle={successReset ? <CheckCircle className="successColor modalIcon"/> : t("EnterNewPasswordTitle")} modalDescription={successReset ? t("SuccessReset") : t("EnterNewPasswordDescription")} close={()=>dispatch(setVisibleNewPasswordModal(false))}><div className="formInputs">
            {successReset ? <button onClick={()=>loginHandler()} type="button" className="redButton modalButtons">{t('Login')}</button>: <><InputPassword classes={"modalInput"} />
                <div className="inputDivWithMessage">
                <input className={confirmPasswordError.length>0 ? "redBorder modalInput" : "modalInput"} type="password" value={confirmPassword} placeholder={t('ConfirmPassword')} onChange={(e)=>setConfirmPassword(e.target.value)} />
                <div>{confirmPasswordError}</div>
                </div>
                <button onClick={()=>saveNewPassword()} type="button" className="redButton modalButtons">{t('Save')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
            </>
            }
    </div>
    </WhiteBackgroundForModal></div>






}

export default NewPasswordModal;