import React from "react";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";
import {
    getConsultationUrl,
    getVisibleConsultationModal, setVisibleConsultationModal,
} from "../features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import InputEmail from "./UI/InputEmail";

import {useState} from "react";


import {
    selectEmail, selectFirstName,
    selectIsAuth,
    selectIsEmailValid, selectIsFirstnameValid, selectIsLastnameValid, selectLastName,
} from "../features/auth/authSlice";
import InputFirstname from "./UI/InputFirstname";
import InputLastname from "./UI/InputLastname";
import {useCreateUserRequestMutation} from "../features/userRequest/userRequestsSlice";
import {CheckCircle, Error} from "@mui/icons-material";
import {useGetAManagerQuery} from "../features/user/userApiSlice";
import {getCountry, getCurrentTitle} from "../features/user/settingsSlice";

const ConsultationModal = () => {
    const [statusLoader, setStatusLoader] = useState(false)
    const visibleConsultationModal = useSelector(getVisibleConsultationModal);
    const email = useSelector(selectEmail);
    const firstname = useSelector(selectFirstName);
    const lastname = useSelector(selectLastName);
    const [statusSend, setStatusSend] = useState(false)
    const [isError, setIsError] = useState(false)
    const isEmailValid = useSelector(selectIsEmailValid)
    const isFirstnameValid = useSelector(selectIsFirstnameValid)
    const isLastNameValid = useSelector(selectIsLastnameValid)
    const [successReset, setSuccessReset] = useState(false)
    const [text, setText] = useState("")
    const isAuth = useSelector(selectIsAuth)
    const currentTitle = useSelector(getCurrentTitle)
    const country = useSelector(getCountry)
    const dispatch = useDispatch();
    const [createRequest] = useCreateUserRequestMutation()

    const {
        data,
        isLoading,
        isSuccess: isSuccessManager,
        isError: isErrorManager
    } = useGetAManagerQuery();
    //const { pathname } = useLocation();

    const { t } = useTranslation();

    const send = () => {
        if(isEmailValid && isFirstnameValid && isLastNameValid && isSuccessManager){
            const object = {path: window.location.pathname, text: text, email: email, firstname: firstname, lastname: lastname, auth: isAuth, manager: data.email, page: currentTitle, country: country.country_name}
            createRequest(object).then(()=>{
                setStatusSend(true)
            }).catch(()=>{
                setIsError(true)
            })
        }
    }

    const close = () => {
        dispatch(setVisibleConsultationModal(false))
        setStatusSend(false)
        setIsError(false)
    }

    let description = "";
    let title = t("AskQuestion");

    let content = <div className="formInputs"><>{!isAuth && <>
        <InputFirstname classes="modalInput"/>
        <InputLastname classes="modalInput"/>
        <InputEmail classes="modalInput"/>
    </>
    }
        <div className="modalTextArea">
                   <textarea className="modalInput" value={text} onChange={(e)=>setText(e.target.value)} placeholder={t("YourMessage")}>

            </textarea>
        </div>

        <button onClick={()=>send()} type="button" className="redButton modalButtons flexCenterCenter modalInput">{t('Send')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>

    </></div>;

    if(statusSend){
        content = ""
        title = <CheckCircle className="successColor modalIcon"/>
        description = <>{t("ThanksForYourRequest.part1")} <br/><br/> {t("ThanksForYourRequest.part2")}
            {
                //<br/><br/> {t("ThanksForYourRequest.part3")}<br/><br/><b>Vera Ivanova</b><br/><br/><a href="mailto:vera.i@many-tests.com"><b>vera.i@many-tests.com</b></a>}
            }

        </>
    }
    if(isError){
        content = ""
        title = <Error className="errorColor modalIcon"/>
        description = <>{t("SendRequestError.part1")} <br/><br/> {t("SendRequestError.part2")} <br/><br/> {t("SendRequestError.part3")}<br/><br/><b>Vera Ivanova</b><br/><br/><a href="mailto:vera.i@many-tests.com"><b>vera.i@many-tests.com</b></a></>
    }


    return <div style={{display: visibleConsultationModal ? "block" : "none"}}>
        <WhiteBackgroundForModal modalTitle={title} modalDescription={description} close={()=>close()}>
            {content}

    </WhiteBackgroundForModal></div>



}

export default ConsultationModal;