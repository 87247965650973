import {CREATE_REQUEST_PRICE, GET_REQUEST_PRICE_BY_PRODUCT_ID} from "../../app/repository";
import {apiSlice} from "../../app/api/apiSlice";

export const requestPriceSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        getRequestPriceByProductId: builder.query({
            query: id =>  GET_REQUEST_PRICE_BY_PRODUCT_ID+id,
            providesTags: (result, error, arg) => [{ type: 'RequestPrice', id: arg }]

        }),
        createRequestPrice: builder.mutation({
            query: (formData) => {
                return {
                    url: CREATE_REQUEST_PRICE,
                    method: 'POST',
                    body: formData,
                }

            },
            invalidatesTags:  (result, error, arg) => [{ type: 'RequestPrice', id: arg.id }]
        }),
    })
})

export const {
    useGetRequestPriceByProductIdQuery,
    useCreateRequestPriceMutation
} = requestPriceSlice