import {Link} from "react-router-dom";
import Countdown from "react-countdown";
import {useEffect, useState} from "react";
import {useBuyMutation} from "../purchase/purchaseApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAuth} from "../../../common/features/auth/authSlice";
import React from "react";
import {
    setBlackBackground,
    setVisibleLoginModal
} from "../../../common/features/application/applicationSlice";
import useToolService from "../../../common/hooks/useToolService";
import {useTranslation} from "react-i18next";
import {
    getCountry, getCurrencies,
    getLocalizedLanguage,
    getMobile, getNeededCurrencies,
    setCurrentTitle, setGlobalLoader
} from "../../../common/features/user/settingsSlice";

import {useCheckPaidProductByIdQuery} from "../../../common/features/user/userApiSlice";
import OfferCheckboxBlock from "../../../common/features/offerCheckbox/OfferCheckboxBlock";
import {getOfferAccepted, setOfferLink} from "../../../common/features/offerCheckbox/offerCheckboxBlockSlice";
import CurrencySelect from "../../../common/features/currency/CurrencySelect";


const TestBuyPageDesktopInside = ({data, alias}) => {
    const isMobile = useSelector(getMobile)
    const { t } = useTranslation();
    const [paid, setPaid] = useState(false);
    const dateTime = Date.now();
    const isAuth = useSelector(selectIsAuth);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(0);
    const [price, setPrice] = useState(0);
    const country = useSelector(getCountry)
    const language = useSelector(getLocalizedLanguage)
    const offerAccepted = useSelector(getOfferAccepted);
    const currencies = useSelector(getCurrencies);
    const [currentCurrency, setCurrentCurrency] = useState(1);
    const [currentCurrencyObject, setCurrencyObject] = useState("");

    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }
    const {
        data: paidData,
        isLoading,
        isSuccess,
        isError,
        error
    } = useCheckPaidProductByIdQuery(data.id);

    const {getTranslatedValue} = useToolService();
    const countPercentage = (value, percentage) => {
        return value-(value*(percentage/100)).toFixed(2)
    }
    const [buy] = useBuyMutation();



    useEffect(()=>{
        if(currencies.length > 0 && price > 0){
            const tempCur = currencies.filter((currency)=>currency.Position===currentCurrency)[0];
            const tempString = "(≈"+((price / Number.parseFloat(tempCur.Value.replace(/,/, '.')))*tempCur.Nominal).toFixed(2)+tempCur.Symbol+")";
            setCurrencyObject(tempString);
        }

    },[currentCurrency, currencies, price])

    useEffect(()=>{

        dispatch(setCurrentTitle(getTranslatedValue(data.titles)))

            const item = [...data.prices].sort((a,b)=>a.value-b.value)[0];
            setSelected(item.id);
            if(item.globalSale){
                setPrice(item.value-(item.value*(item.globalSale.percentage/100)));
            }else{
                setPrice(item.value);
            }


    }, [])





    const scroll = async () => {
        try{
            dispatch(setGlobalLoader(true))
            const result = await buy({priceId: selected, productId: data.id, total: price, country: country.country_name, paymentSystem: "Robocassa"});
            dispatch(setGlobalLoader(false))
            //console.log(result)
            if(result?.data?.success){
                window.location.replace(result?.data?.url)
            }

        }catch (err) {
            dispatch(setGlobalLoader(false))
            console.log(err)
        }


    }
    const selectHandler = (item) => {
        const value = item.globalSale ? countPercentage(item.value, item.globalSale.percentage) : item.value
        setPrice(value)
        setSelected(item.id)
    }


    const content = <div className="buyForm">
                <h2>{t('OrderForm')}</h2>
                <div className="buyFormRadioButtons" itemProp="offers" itemScope itemType="http://schema.org/Offer">
                    {
                        [...data.prices].sort((a,b)=>a.value-b.value).map(price=>{
                            return <label key={price.id} className="custom-radio-button" style={{color: selected !== price.id ? "rgba(38, 34, 47, 0.5)" : "rgba(38, 34, 47, 1)"}}>
                                <input checked={selected === price.id} onChange={()=>{selectHandler(price)}} type="radio"/>
                                <span className="helping-el"></span>
                                <div>
                                    <span className="label-text">{getTranslatedValue(price.titles)}</span> - <span className="price" style={{fontWeight: selected === price.id ? 500 : 400}}>{price.globalSale ? <><span className="through" itemProp="price">₽{price.value}</span> <span className="red">₽{countPercentage(price.value, price.globalSale.percentage)}</span> - <span className="red"><Countdown date={new Date(price.globalSale.endSale)} /></span></> : <span itemProp="price">{price.value}₽</span>}
                                     <CurrencySelect price={price.value} callback={(value)=>{setCurrentCurrency(value)}}/></span><br/>
                                    <meta itemProp="priceCurrency" content="RUB"/>
                                    <span className="label-description" >{getTranslatedValue(price.descriptions)}</span>
                                </div>
                            </label>
                        })
                    }

                </div>

                <div className="totalPriceBlock">
                    <span><b>{t('ToPay')}:</b> <span className="totalPrice">₽{price} {(currentCurrencyObject !== null && currentCurrencyObject !== undefined) && <span>{currentCurrencyObject}</span>}</span></span>
                </div>
                <div className="payBlock">
                    {paidData ?
                        <div><Link className="greenButton linkAsButtonSimple roundedButton" to={"/"+language+"/panel"}>{t('Account')}</Link></div> :
                        <button disabled={!offerAccepted} onClick={()=>{isAuth ? scroll() : openLoginModal()}} className="roundedButton redButton">{t('Pay')}</button>
                    }
                </div>
                {
                    !paidData && <OfferCheckboxBlock/>
                }

            </div>



    return <>
        {content}
    </>



}

export default TestBuyPageDesktopInside;