import Countdown from "react-countdown";
import useToolService from "../../hooks/useToolService";
import {useTranslation} from "react-i18next";
import React from "react";
import {useSelector} from "react-redux";
import {getCurrencies} from "../user/settingsSlice";
const PriceBlockForMainProduct = ({item, dateTime, first}) => {
    const {getTranslatedValue} = useToolService();
    const { t } = useTranslation();
    const currencies = useSelector(getCurrencies);
    let content;
    if(item){
        const tempCur = currencies.filter((currency)=>currency.Position===1)[0];
        const tempString = (tempCur !== null && tempCur !== undefined) ? "(≈"+((item.value / Number.parseFloat(tempCur.Value.replace(/,/, '.')))*tempCur.Nominal).toFixed(2)+tempCur.Symbol+")" : "";
        if(item.value > 0){
           // content = <div className="priceLine">{first && t("From")+" "} <span >₽{item.value}</span></div>
            content = <div className="priceLine"><span >{item.value}₽ {tempString}</span></div>
        }else{
            content = <div className="priceLine"><span>{t("Free")}</span></div>
        }
    }else{
        content = <div className="priceLine"><span>{t("AskPrice")}</span></div>
    }
    return <div>
        {
          //  first && t("From")+" "
        }
        {
           // item.globalSale ? <><span>{getTranslatedValue(item.titles)}</span> <span className="through lightColor">${item.value}</span> <span>${(item.value-(item.value*(item.globalSale.percentage/100))).toFixed(2)}</span><span className="saleFont"> <span className="red">-{item.globalSale.percentage}%</span> <span className="red"><Countdown date={new Date(item.globalSale.endSale)} /></span></span></> : <span >${item.value}</span>
        }
        {
            content
        }
    </div>
}

export default PriceBlockForMainProduct;