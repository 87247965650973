import ArchiveItem from "../archive/ArchiveItem";
import {useDispatch} from "react-redux";
import {setCat4Order} from "../user/settingsSlice";
import React, {useEffect} from "react";
const Packs = ({data}) => {

    const dispatch = useDispatch()
        const result = data.reduce((acc, currentItem) => {
            acc[currentItem.type] = []
            acc[currentItem.type].push(currentItem)
            return acc;
        }, {});

    useEffect(()=>{
        if(result.CAT4){
            dispatch(setCat4Order(data[0].orderId))
        }
    },[result])


        if(result.CAT4){

            return <ArchiveItem alias="tests/cat4/get" item={{status: true, orderDate: data[0].orderDate, orderActive: false, orderId: data[0].orderId, productId: data[0].productId, picture: data[0].picture, titles: data[0].titles, id: 0}}  />
        }

    return <div>

    </div>

}
export default Packs;