
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../features/user/settingsSlice";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import React from "react";
import {
    selectEmail,
    selectPassword,
    selectPasswordError,
    setIsPasswordValid, setPassword, setPasswordError,

} from "../../features/auth/authSlice";

const InputPassword = ({classes}) => {
    const dispatch = useDispatch();
    const password = useSelector(selectPassword);
    const passwordError = useSelector(selectPasswordError);
    const language = useSelector(getLanguage)
    const { t } = useTranslation();

       const passwordValidate = () => {
        if(password && password.length !== 0){

            if(password.length < 8 || password.length > 16){
                dispatch(setPasswordError(t("PasswordMustBe")))
                dispatch(setIsPasswordValid(false))
            }
            else{
                dispatch(setPasswordError(t("")))
                dispatch(setIsPasswordValid(true))
            }
        }else{
            dispatch(setIsPasswordValid(false))
            dispatch(setPasswordError(""))
        }
    }

    useEffect(()=>{
        passwordValidate()
    },[password,language])

    return <div className="inputDivWithMessage"><input className={passwordError.length>0 ? "redBorder "+classes : classes} value={password} onChange={(e)=>dispatch(setPassword(e.target.value))} type="password" placeholder={t("Password")} /><div>{passwordError}</div></div>

}

export default InputPassword;