import {apiSlice} from "../../app/api/apiSlice";

import {
    GET_MAIN_CAT4,
    GET_CAT4_TEST, GET_RESULT_EXAMPLE, CHECK_CAT4
} from "../../app/repository";

export const cat4Slice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMainCat4: builder.query({
            query: ({orderId}) => {
                return GET_MAIN_CAT4+"?orderId="+orderId},
            providesTags: (result, error, arg) => [{ type: 'Cat4', id: arg }]

        }),
        getCat4Test: builder.query({
            query: ({orderId, entityId}) => {
                return GET_CAT4_TEST+"?orderId="+orderId+'&entityId='+entityId},
            providesTags: (result, error, arg) => [{ type: 'Cat4', id: arg }]

        }),
        resultExample: builder.query({
            query: ({entityId}) => {
                return GET_RESULT_EXAMPLE+"?&entityId="+entityId},
            providesTags: (result, error, arg) => [{ type: 'Results', id: arg }]

        }),
        checkCat4: builder.mutation({
            query: ({body, orderId}) => {

                return ({
                url: CHECK_CAT4+orderId,
                method: 'POST',
                body: body
            })},

            invalidatesTags:  (result, error, arg) => [{ type: 'Results', id: arg.id }]

        }),

    })
})

export const {
    useGetMainCat4Query,
    useGetCat4TestQuery,
    useCheckCat4Mutation,
    useResultExampleQuery
} = cat4Slice