import {Link} from "react-router-dom";
import useToolService from "../../hooks/useToolService";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage} from "../user/settingsSlice";


const Cat4HiddenBigBlock = ({groupByPack, key1, key2, boxContent, linkInsideAccordion, orderId}) => {
    const {getTranslatedValue} = useToolService();
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false)
    const language = useSelector(getLocalizedLanguage)


    return  <nav className="accordion arrows">
        <header className="box">
            {//<label htmlFor="acc-close" className="box-title">{key2}</label>
                <label htmlFor={key1+key2} className="box-title" onClick={()=>setVisible(!visible)}>{key2} </label>
            }
        </header>
    <div id={key1+key2} style={{display: visible ? "block" : "none"}} >
        {Object.keys(groupByPack[key1][key2]).map((key3, index3) => {
            return <div key={key3}>
                <input className="accordionInput" type="radio" name="accordion" id={key1+key2+key3}/><section className="box">
                <label className="box-title" htmlFor={key1+key2+key3}>{key3}</label>
                <label className="box-close" htmlFor="acc-close"></label>
                {
                    groupByPack[key1][key2][key3].map((test)=>{
                        return <div key={test.id} className={boxContent}>
                            <div className={linkInsideAccordion}>
                                <b>{getTranslatedValue(test.titles)}</b>
                                <Link to={"/"+language+'/panel/tests/cat4/start?orderId='+orderId+'&entityId='+test.id} className="accordionButton greenButton roundedButton">{t('StartTest')}</Link>

                            </div>

                        </div>
                    })
                }

            </section>
            </div>

        })}


        <input className="accordionInput"  type="radio" name="accordion" id="acc-close"/>
    </div>
    </nav>
}

export default Cat4HiddenBigBlock;