import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {GET_CURRENCIES} from "../../app/repository";

export const settingsApiSlice =  createApi({
    reducerPath: 'settingsApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: "",
    }),
    tagTypes: ['settingsApi'],
    endpoints: builder => ({
        getDefaultCountry: builder.query({
            query: () => "https://ipapi.co/json/",
            providesTags: (result, error, arg) => [{ type: 'settingsApi', id: arg }]
        }),
        getCurrencies: builder.query({
            query: () => GET_CURRENCIES,
            providesTags: (result, error, arg) => [{ type: 'settingsApi', id: arg }]
        }),
    })
})

export const {
    useGetDefaultCountryQuery,
    useGetCurrenciesQuery
} = settingsApiSlice