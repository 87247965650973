import OneSideOptions from "./OneSideOptions";
import React from "react";
const TwoSideOptions = ({options, questionId, multiplicity}) => {

    const leftSide = options.filter(option=>option.horizontalPosition==="LEFT")
    const rightSide = options.filter(option=>option.horizontalPosition==="RIGHT")

    return <div className="twoSidedQuestionBlock">
        <OneSideOptions multiplicity={multiplicity} options={leftSide} questionId={questionId} side={"LEFT"}/>
        <OneSideOptions multiplicity={multiplicity} options={rightSide} questionId={questionId} side={"RIGHT"}/>
    </div>
}

export default TwoSideOptions;