import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getVisibleErrorModal,
    getVisibleSuccessModal, setVisibleErrorModal,
    setVisibleSuccessModal
} from "../features/application/applicationSlice";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {CheckCircle, Error} from "@mui/icons-material";
import React from "react";

const ErrorMessageModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const visibleErrorModal = useSelector(getVisibleErrorModal);

    return <div style={{display: visibleErrorModal ? "block" : "none"}}><WhiteBackgroundForModal modalTitle="" modalDescription="" close={()=>dispatch(setVisibleErrorModal(false))}>
        <div className="successMessage">
            <div><Error className="errorColor modalIcon"/></div>
            <div><h2 className="errorColor">{t("SendRequestError.part1")}</h2></div>

        </div>
    </WhiteBackgroundForModal></div>
}

export default ErrorMessageModal;