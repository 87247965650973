import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../features/user/settingsSlice";
import {useTranslation} from "react-i18next";
import {useGetAllProductsQuery} from "../features/product/productsSlice";
import MainPageTestCatalogMobile from "../../mobile/features/tests/MainPageTestCatalogMobile";
import MainPageTestCatalog from "../../desktop/features/tests/MainPageTestCatalog";

const PageNotFound = () => {
    const {t} = useTranslation()
    const isMobile = useSelector(getMobile)
    const dispatch = useDispatch()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery();
    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    let content;

    if(isSuccess){

        content = isMobile
            ? <MainPageTestCatalogMobile data={data} removeTitle={true} />
            : <MainPageTestCatalog data={data} removeTitle={true} />
    }

    return <div className="articleBlock">
        <Helmet>
            <title>{t("PageNotFound")}</title>
            <meta name='description' content={t("PageNotFound")} />
        </Helmet>
        <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide">
                    <div className="pageNotFound">
                        <br/><br/>
                        <h1>{t("PageNotFound")}</h1>
                        <br/><br/>
                        <span className="smallLeftTitle">{t("MightBeInteresting")}</span>
                        {
                            content
                        }
                    </div>


                </div>
            </div>
        </div>
    </div>
}

export default PageNotFound;