import {apiSlice} from "../../app/api/apiSlice";
import {
    CHECK_PAID_PRODUCT_BY_ID,
    GET_A_MANAGER,
    USER_UPDATE_AVATAR,
    USER_UPDATE_FIRSTNAME, USER_UPDATE_LANGUAGE,
    USER_UPDATE_LASTNAME,
    USER_UPDATE_PASSWORD, USER_UPDATE_PHONE
} from "../../app/repository";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateAvatar: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_AVATAR,
                method: "PATCH",
                body: object
            })
        }),
        updateFirstname: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_FIRSTNAME,
                method: "PATCH",
                body: object
            })
        }),
        updatePhone: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_PHONE,
                method: "PATCH",
                body: object
            })
        }),
        updateLastname: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_LASTNAME,
                method: "PATCH",
                body: object
            })
        }),
        updatePassword: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_PASSWORD,
                method: "PATCH",
                body: object
            })
        }),
        updateLanguage: builder.mutation({
            query: (object) => ({
                url: USER_UPDATE_LANGUAGE,
                method: "PATCH",
                body: object
            })
        }),
        getAManager: builder.query({
            query: () => GET_A_MANAGER,
            providesTags: (result, error, arg) => [{ type: 'AManager', id: arg }]
        }),

        checkPaidProductById: builder.query({
            query: id =>  CHECK_PAID_PRODUCT_BY_ID+id,

        }),
    })
})


export const {
    useCheckPaidProductByIdQuery,
    useUpdateAvatarMutation,
    useUpdateLanguageMutation,
    useUpdatePasswordMutation,
    useUpdateLastnameMutation,
    useUpdatePhoneMutation,
    useUpdateFirstnameMutation,
    useGetAManagerQuery
} = userApiSlice