import {apiSlice} from "../../app/api/apiSlice";
import {
    CHECK,
    CONFIRMATION,
    HARD_PASSWORD_RESET,
    LOGIN,
    NEW_PASSWORD_RESET,
    REGISTRATION,
    RESET
} from "../../app/repository"
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: object => ({
                url: LOGIN,
                method: "POST",
                body: object
            })
        }),
        registration: builder.mutation({
            query: (object) => ({
                url: REGISTRATION,
                method: "POST",
                body: object
            })
        }),
        reset: builder.mutation({
            query: (object) => ({
                url: RESET,
                method: "POST",
                body: object
            })
        }),
        confirmation: builder.mutation({
            query: (object) => ({
                url: CONFIRMATION,
                method: "POST",
                body: object
            })
        }),
        check: builder.query({
            query: () => ({
                url: CHECK,
            })
        }),
        newPasswordReset: builder.mutation({
            query: (object) => ({
                url: NEW_PASSWORD_RESET,
                method: "POST",
                body: object

            })
        }),
        hardPasswordReset: builder.mutation({
            query: (token) => ({
                url: HARD_PASSWORD_RESET,
                method: "POST",
                body: token

            })
        })
    })
})

export const {
    useCheckQuery,
    useNewPasswordResetMutation,
    useLoginMutation,
    useResetMutation,
    useConfirmationMutation,
    useRegistrationMutation,
    useHardPasswordResetMutation,
} = authApiSlice