import {Link} from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, setLanguage} from "../../common/features/user/settingsSlice";
import TopbarUser from "./TopbarUser";
import {selectIsAuth} from "../../common/features/auth/authSlice";
import {setBlackBackground, setVisibleLoginModal} from "../../common/features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import {useUpdateLanguageMutation} from "../../common/features/user/userApiSlice";


const TopbarDesktop = () => {
    const {t, i18n } = useTranslation();

    const isAuth = useSelector(selectIsAuth);
    const language = useSelector(getLocalizedLanguage)
    const languageBig = useSelector(getLanguage)
    const dispatch = useDispatch();
    const [updateLanguage] = useUpdateLanguageMutation()


    const changeLanguageHandler = (code) => {
          if(isAuth){
            updateLanguage(code)
        }

        dispatch(setLanguage(code))
        i18n.changeLanguage(code)
    }

    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }

   /* useEffect(()=>{

        if((i18n.language !== language)  ){
            dispatch(setLanguage(i18n.language))
        }
    },[i18n.language])
*/

    return <div className="topBarMainBlock" ><div className="topbarDesktop wrapper">
        <div>
            <div className="logoBlock">
                <Link to={"/"+language+"/"}>
                <img className="logo" src="/img/logo2.webp" alt={"Many Tests"} />
                </Link>
            </div>
            <div><ul className="topbarFullMenu">
                <li><Link to={"/"+language+"/products/"}>{t('Products')}</Link></li>
                <li><Link to={"/"+language+"/panel"}>{t('Account')}</Link></li>
                {isAuth && <li><Link to={"/"+language+"/panel/results/"}>{t('Results')}</Link></li>}
            </ul></div>
        </div>
        <div>
            <div>{isAuth ? <TopbarUser/>  : <span className="singIn" onClick={()=>{openLoginModal()}}>{t('SignIn')}</span>}</div>
            <div><ReactFlagsSelect
                selected={languageBig}
                countries={["US", "ES", "RU", "CN", "DE", 'KZ', 'FR']}
                customLabels={{"US": "English","ES": "Español", "RU": "Русский", "CN":"中国人", "DE":"Deutsch", "KZ":"Қазақ тілі", "FR":"Français"}}
                placeholder="Select Language"
                onSelect={(code) => changeLanguageHandler(code)}
            /></div>
        </div>
    </div>


    </div>
}

export default TopbarDesktop;