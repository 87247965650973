import {useGetAllProductsQuery} from "../../common/features/product/productsSlice";
import {useGetCat4TestQuery} from "../../common/features/cat4/cat4Slice";

const Temp = ()  => {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCat4TestQuery;

    console.log(data)

    return <div></div>
}

export default Temp;