import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {apiSlice} from "./api/apiSlice";
import {authReducer} from "../features/auth/authSlice";
import {settingsReducer} from "../features/user/settingsSlice";
import {applicationReducer} from "../features/application/applicationSlice";
import {productsSlice} from "../features/product/productsSlice";
import {settingsApiSlice} from "../features/user/settingsApiSlice";
import {userRequestsSlice} from "../features/userRequest/userRequestsSlice";
import {testProgressReducer} from "../features/commonTestData/testProgressLine";
import {timerReducer} from "../features/commonTestData/timerSlice";
import {testAnswersReducer} from "../features/commonTestData/testAnswersSlice";
import {resultsSlice} from "../features/results/resultsSlice";
import {languageLevelTestReducer} from "../features/languageLevelTest/languageLevelTestSlice";
import {offerCheckboxBlockReducer} from "../features/offerCheckbox/offerCheckboxBlockSlice";

const customReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    settings:  settingsReducer,
    application: applicationReducer,
    testProgressSlice: testProgressReducer,
    timerSlice: timerReducer,
    testAnswersSlice: testAnswersReducer,
    languageLevelTestSlice: languageLevelTestReducer,
    offerCheckboxBlockSlice: offerCheckboxBlockReducer,
    [productsSlice.reducerPath]: productsSlice.reducer,
    [userRequestsSlice.reducerPath]: userRequestsSlice.reducer,
    [settingsApiSlice.reducerPath]: settingsApiSlice.reducer,


});


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, customReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck:{
            ignoreActions:[
                FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER

            ]
        }
    }).concat(apiSlice.middleware).concat(productsSlice.middleware).concat(settingsApiSlice.middleware).concat(userRequestsSlice.middleware)
})


export const persistor = persistStore(store)