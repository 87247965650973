import React from "react";
import {CheckCircle} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {getVisibleSuccessModal, setVisibleSuccessModal} from "../features/application/applicationSlice";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";

const SuccessMessageModal = ({message}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const visibleSuccessModal = useSelector(getVisibleSuccessModal);

    return <div style={{display: visibleSuccessModal ? "block" : "none"}}><WhiteBackgroundForModal modalTitle="" modalDescription="" close={()=>dispatch(setVisibleSuccessModal(false))}>
        <div className="successMessage">
            <div><CheckCircle className="successColor modalIcon"/></div>
            <div><h2 className="successColor">{t("Success")}</h2></div>
            <div>{message}</div>
        </div>
    </WhiteBackgroundForModal></div>
}

export default SuccessMessageModal;