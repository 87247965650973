import OneSideOptionResult from "./OneSideOptionResult";
import {useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import {useTranslation} from "react-i18next";
import React from "react";

const TwoSideOptionResult = ({options}) => {
    const isMobile = useSelector(getMobile)
    const leftSide = options.filter(option=>option.horizontalPosition==="LEFT")
    const rightSide = options.filter(option=>option.horizontalPosition==="RIGHT")
    const { t } = useTranslation();
    return <div className={isMobile ? "twoSidedQuestionBlockResultMobile" : "twoSidedQuestionBlock"}>
        <br/>
        {isMobile && <h4 className="headerForSideName">{t("LeftSide")}</h4>}
        <OneSideOptionResult  options={leftSide}  />
        {isMobile &&<><br/>
        <h4 className="headerForSideName">{t("RightSide")}</h4></>}
        <OneSideOptionResult  options={rightSide} />
    </div>
}

export default TwoSideOptionResult;