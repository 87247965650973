import React from "react";



export function SelectColumnFilter({
                                       column: { filterValue, setFilter, preFilteredRows, id },
                                   }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>

            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}
export function MySelectColumnFilter({
                                         column: { filterValue, setFilter, preFilteredRows, id },
                                     }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

function getOption(option, i){
    let obj = {id: 0, firstName: "Нет", lastName: ""}
    if(option!=null){
        obj = option;
    }

    return (
        <option key={i} value={obj.id}>

            {obj.firstName+" "+obj.lastName}
        </option>
    )

}

export function SelectPersonFilter({
                                       column: { filterValue, setFilter, preFilteredRows, id },
                                   }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            let obj = {id: 0, firstName: "Нет", lastName: ""}
            if(row.values[id]!=null){
                obj = row.values[id];
            }
            //options.add(obj.firstName+" "+obj.lastName)

            let u = false;
            options.forEach(value => {
                if(value.id === obj.id)
                    u = true;
            })
            if(!u){
                options.add(obj);
            }


        })

        return [...options.values()]
    }, [id, preFilteredRows])

    // const unique = [...new Set(options.map(item => item.id))];
    // const unique = [...new Set(options.map(item =>        [item[id], item])).values()];

    // Render a multi-select box
    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>
            {options.map((option, i) => (
                getOption(option, i)
            ))}
        </select>
    )
}
export function SelectActivityFilter({
                                         column: { filterValue, setFilter, preFilteredRows, id },
                                     }) {

    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>
            <option key="1" value={true}>
                Активные
            </option>
            <option key="2" value={false}>
                Неактивные
            </option>
            ))}
        </select>
    )
}
export function SelectSpamFilter({
                                     column: { filterValue, setFilter },
                                 }) {

    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>
            <option key="1" value={true}>
                Спам
            </option>
            <option key="2" value={false}>
                Не спам
            </option>
            ))}
        </select>
    )
}

export function SelectRealFilter({
                                     column: { filterValue, setFilter },
                                 }) {

    return (

        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Все</option>
            <option key="1" value={true}>
                Да
            </option>
            <option key="2" value={false}>
                Нет
            </option>
            ))}
        </select>
    )
}
export  function inclFilterFn(rows, ids, filterValue) {
    return rows.filter(row => {

        return ids.some(id => {
            const rowValue = row.values[id]
            return rowValue.includes(filterValue)
        })
    })

}
export  function inclPersonFilterFn(rows, ids, filterValue) {

    return rows.filter(row => {

        return ids.some(id => {
            const rowValue = row.values[id]

            let iden;

            if(rowValue === null){
                iden = 0
            }
            else{
                iden = rowValue.id

            }

            return iden === Number(filterValue)
        })
    })

}
export  function myIncludesFilter(rows, ids, filterValue) {
    return rows.filter(row => {

        return ids.some(id => {
            const rowValue = row.values[id]

            return rowValue.includes(filterValue)
        })
    })

}

export  function activityFilterFn(rows, ids, filterValue) {
    return rows.filter(row => {

        return ids.some(id => {
            const rowValue = row.values[id]
            let val = false;
            if(filterValue === 'true')
                val = true
            return rowValue === val
        })
    })

}

export function SliderColumnFilter({
                                       column: { filterValue, setFilter, preFilteredRows, id },
                                   }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button className="resetButton" onClick={() => setFilter(undefined)}>Сбросить</button>
        </>
    )
}

export function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}
export  function DefaultColumnFilter({
                                         column: { filterValue, preFilteredRows, setFilter },
                                     }) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}