import {useResultExampleQuery} from "../cat4/cat4Slice";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../user/settingsSlice";
import ItemQuestionResult from "../results/ItemQuestionResult";
import React, {useEffect} from "react";

const TestResult = () => {
    const [searchParams] = useSearchParams()
    const entityId = searchParams.get("entityId");
    const isMobile = useSelector(getMobile)
    const {data, isLoading, isSuccess, isError, error} = useResultExampleQuery({entityId: entityId})
    const dispatch = useDispatch()
    let content;

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    if(isSuccess){
        content = data.map((item, index) => <ItemQuestionResult key={item.id} index={index} item={item} />)

    }

    return <div className={isMobile ? "wrapper2": "wrapper"}>
        <div className="buyCard">
        <div className="buyCardInsideWide">
            {content}
        </div></div>
    </div>
}

export default TestResult;