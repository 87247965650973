import { useTimer } from 'react-timer-hook';
import {useEffect} from "react";
import React from "react"
import {getFullSeconds, setHours, setMinutes, setSeconds} from "../features/commonTestData/timerSlice";
import {useDispatch, useSelector} from "react-redux";

const MyTimer = ({time, d, h, m, s, callback}) => {
    const dispatch = useDispatch()
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + time);
    const fullSeconds = useSelector(getFullSeconds)
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => callback() });
    const digitsToStyle = (digs) =>{
        const leftDigit = digs >= 10 ? digs.toString()[0] : '0';
        const rightDigit = digs >= 10 ? digs.toString()[1] : digs.toString();
        return leftDigit+""+rightDigit
    }
    useEffect(()=>{
        dispatch(setHours(hours));
        dispatch(setMinutes(minutes));
        dispatch(setSeconds(seconds));

    },[hours, minutes, seconds ])

    let colorClass;
    if(days === 0 && hours === 0 && minutes === 0){
        if(seconds <= 20 && seconds > 10){
            colorClass = "yellowColor";
        }
        if(seconds <= 10){
            colorClass = "redColor";
        }

    }

    return <div className={colorClass}>
        {d && <span>{days}:</span>}{h &&<span>{digitsToStyle(hours)}:</span>}{m && <span>{digitsToStyle(minutes)}:</span>}{s &&<span>{digitsToStyle(seconds)}</span>}
    </div>
}
export default React.memo(MyTimer);