import {useDispatch, useSelector} from "react-redux";
import {addAnswer, getAnswerByQuestion, getAnswers} from "../commonTestData/testAnswersSlice";
import {getMobile} from "../user/settingsSlice";
import React from "react";
import useToolService from "../../hooks/useToolService";
const PictureOptions = ({options, questionId, side, multiplicity}) => {

    const {checkAnswerInsideAnswers} = useToolService()
    const dispatch = useDispatch()
    const isMobile = useSelector(getMobile)
    let questionAnswersSide = "questionAnswersSide";

    if(isMobile){
        questionAnswersSide = "questionAnswersSide questionAnswersSideMobile";
    }

    return <div className={questionAnswersSide}>
        {

            options.map((option,index)=>{

                return <div key={option.id}  onClick={()=>{

                    dispatch(addAnswer({idTest: questionId, idAnswer: option.id, side: side, multiplicity: multiplicity}))}
                }>
                    <span>{index+1}:</span>
                    <div className={checkAnswerInsideAnswers(questionId).includes(option.id) ? "imageOptionSelect selectedAnswer" : "imageOptionSelect"} >
                        <div dangerouslySetInnerHTML={{__html: option.title}}/>
                    </div>

                </div>
            })}
    </div>
}

export default PictureOptions;