import TopbarDesktop from "../../components/TopbarDesktop";
import React from "react";
import InputLastname from "../../../common/components/UI/InputLastname";
import InputFirstname from "../../../common/components/UI/InputFirstname";
import InputPhone from "../../../common/components/UI/InputPhone";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAvatar, selectConfirmPassword,
    selectFirstName, selectIsConfirmPasswordValid,
    selectIsFirstnameValid,
    selectIsLastnameValid, selectIsPasswordValid,
    selectLastName,
    selectPassword,
    selectPhone,
    setAvatar,
    setConfirmPasswordError,
    setFirstnameError,
    setIsConfirmPasswordValid,
    setIsFirstnameValid,
    setIsLastnameValid,
    setIsPasswordValid,
    setLastnameError,
    setPasswordError,
    setPhoneError
} from "../../../common/features/auth/authSlice";
import {useUploadFileMutation} from "../../../common/features/file/filesSlice";
import {useTranslation} from "react-i18next";
import InputPassword from "../../../common/components/UI/InputPassword";
import InputConfirmPassword from "../../../common/components/UI/InputConfirmPassword";
import {
    useUpdateAvatarMutation,
    useUpdateFirstnameMutation,
    useUpdateLastnameMutation, useUpdatePasswordMutation, useUpdatePhoneMutation
} from "../../../common/features/user/userApiSlice";
import useToolService from "../../../common/hooks/useToolService";
import {usePhoneValidation} from "react-international-phone";
import {useState} from "react";
import {getMobile} from "../../../common/features/user/settingsSlice";

const Profile = () => {
    const dispatch = useDispatch();
    const lastName = useSelector(selectLastName);
    const firstName = useSelector(selectFirstName);
    const isFirstnameValid = useSelector(selectIsFirstnameValid)
    const isLastNameValid = useSelector(selectIsLastnameValid)
    const avatar = useSelector(selectAvatar);
    const [doUpload] = useUploadFileMutation()
    const [updateAvatar] = useUpdateAvatarMutation()
    const [updateFirstname] = useUpdateFirstnameMutation()
    const [updateLastname] = useUpdateLastnameMutation()
    const [updatePhone] = useUpdatePhoneMutation();
    const {checkEmptyLength} = useToolService()
    const phone = useSelector(selectPhone)
    const phoneValidation = usePhoneValidation(phone);
    const [statusLoaderInformation, setStatusLoaderInformation] = useState(false)
    const [statusLoaderInformationSend, setStatusLoaderInformationSend] = useState(false)
    const [statusLoaderPasswordSend, setStatusLoaderPasswordSend] = useState(false)
    const [statusLoaderPassword, setStatusLoaderPassword] = useState(false)
    const password = useSelector(selectPassword);
    const isPasswordValid = useSelector(selectIsPasswordValid)
    const confirmPassword = useSelector(selectConfirmPassword);
    const isConfirmPasswordValid = useSelector(selectIsConfirmPasswordValid)
    const [updatePassword] = useUpdatePasswordMutation();
    const isMobile = useSelector(getMobile)

    const { t } = useTranslation();
    const prepareUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file)
        try {
            const result = await doUpload(formData).unwrap()
            dispatch(setAvatar(result))
            await updateAvatar(result).unwrap()
        }catch (error){
            console.error(error)
        }

        }

        const updateInformation = () => {
            checkEmptyLength(firstName, setFirstnameError, setIsFirstnameValid);
            checkEmptyLength(lastName, setLastnameError, setIsFirstnameValid);
            if(phone.length === 0){
                dispatch(setPhoneError(t("InvalidEmptyField")))
            }
            if(isFirstnameValid && isLastNameValid && phoneValidation.isValid){
                setStatusLoaderInformation(true);
                updateFirstname(firstName)
                updateLastname(lastName)
                updatePhone(phone)
                setStatusLoaderInformation(false);
                setStatusLoaderInformationSend(true)
            }
        }

    const updatePasswordHandler = () => {
        checkEmptyLength(password, setPasswordError, setIsPasswordValid);
        checkEmptyLength(confirmPassword, setConfirmPasswordError, setIsConfirmPasswordValid);
        if(isPasswordValid && isConfirmPasswordValid){
            setStatusLoaderPassword(true)
            updatePassword(password)
            setStatusLoaderPassword(false)
            setStatusLoaderPasswordSend(true)
        }
    }

    return <div>

        <div className="wrapper buyCard">
            <div className={isMobile ? "wrapperInsideMobile profileCenterMobile" : "buyCardInside profileCenter"} >
                <div className="formInputs">
                    <div className="addPhoto">
                        <label htmlFor="file-input" >
                            <div className="rounded-circle-profile" style={{backgroundImage: "url("+avatar+")"}}></div>
                        </label>
                        <input id="file-input" type="file" onChange={
                            (e) => {
                                prepareUpload(e.target.files[0])
                            }
                        }
                        />
                    </div>
                    <InputFirstname classes={isMobile ? "profileInputMobile" : "profileInput"}/>
                    <InputLastname classes={isMobile ? "profileInputMobile" : "profileInput"}/>
                    <InputPhone classes={isMobile ? "profileInputMobile" : "profileInput"}/>
                    <div className="successColor successMessageSmall">{statusLoaderInformationSend && t("Success")}</div>
                    <button type="button" onClick={()=>updateInformation()} className="redButton flexCenterCenter roundedButton">{t('Save')}<img style={{display: statusLoaderInformation ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                    <h2>{t('EnterNewPasswordTitle')}</h2>
                    <InputPassword classes={isMobile ? "profileInputMobile" : "profileInput"}/>
                    <InputConfirmPassword classes={isMobile ? "profileInputMobile" : "profileInput"}/>
                    <div className="successColor successMessageSmall">{statusLoaderPasswordSend && t("Success")}</div>
                    <button type="button" onClick={()=>updatePasswordHandler()} className="redButton flexCenterCenter roundedButton">{t('Save')}<img style={{display: statusLoaderPassword ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                </div>
            </div>
        </div>
    </div>
}

export default Profile;