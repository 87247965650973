import React from "react";
import useToolService from "../../hooks/useToolService";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    AvTimer,
    CheckCircle, HourglassEmpty,
    InsertInvitationOutlined, DriveFileRenameOutline, FindInPage
} from "@mui/icons-material";
import {useSelector} from "react-redux";

import {getLocalizedLanguage} from "../user/settingsSlice";


const ArchiveItem = ({item,  alias}) => {

    const {getTranslatedValue} = useToolService();
    const { t } = useTranslation();
    let status = "";
    const language = useSelector(getLocalizedLanguage)
    const orderDate = new Date(item.orderDate)

    if(item.status === "await"){
        status = <li><HourglassEmpty style={{color: "#858585"}}/>  <span>{t("AwaitFilling")}</span></li>;
    }
    if(item.status === "checking"){
        status = <li><AvTimer style={{color: "#ff8f07"}} />  <span>{t("Checking")}</span></li>;
    }
    if(item.status === "done"){
        status = <li><CheckCircle style={{color: "#28a745"}}/>  <span>{t("Completed")}</span></li>;
    }

    let additionalInformation;

    if(item.type==="essay"){
        let priceIcon = <DriveFileRenameOutline style={{color: "#D85555"}}/>
        if(item.price && item.price.value === 2.5){
            priceIcon = <FindInPage style={{color: "#D85555"}}/>
        }
        additionalInformation = <li>{priceIcon}<span>{item.price && getTranslatedValue(item.price.titles)}</span></li>
    }

    return <div className="testItemInPanel">


            <div className="testItemInPanelPicture" style={{backgroundImage: "url("+item.picture+")"}}></div>
            <div className="testItemInPanelInside">
                <div className="titleItemOrder">{item.titles ? getTranslatedValue(item.titles) : item.title}</div>



            </div>
            <div className="testItemInPanelInside">
                <div className="additionInformationForItem">
                    <ul>
                        <li><InsertInvitationOutlined style={{color: "#4b9af4"}}/> <span>{orderDate.getDate()+"."+(orderDate.getMonth()+1)+"."+orderDate.getFullYear()}</span></li>
                        {status}
                        {
                         //   additionalInformation
                        }
                    </ul>
                </div>
            </div>

        <div className="testItemInPanelInsideMain">

            <div className="testItemInPanelInside">
                <div className="testItemInPanelButtons">
                    {
                        !item.orderActive
                            ? <Link to={alias+"?orderId="+item.orderId+"&entityId="+item.id} className="greenButton linkAsButton">{t("Open")}</Link>
                            : <>
                                <Link to={alias+"?orderId="+item.orderId+"&entityId="+item.id} className="infoButton linkAsButton">{t("Results")}</Link>
                                <Link to={"/"+language+"/products/"+item.alias} className="redButton linkAsButton">{t("Buy")}</Link>
                            </>
                    }
                </div>
            </div>
        </div>



    </div>
}

export default ArchiveItem;