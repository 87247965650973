import './css/style.css';
import React, {useEffect, Suspense, useState} from "react";

import {BrowserRouter, Route, Routes} from "react-router-dom";

import LanguageContext from "./admin/context/LanguageContext";

import MainPageDesktop from "./desktop/features/main/MainPageDesktop";

import {useInitialization} from "./common/hooks/useInitialization";
import {useDispatch, useSelector} from "react-redux";
import {
    setMobile,
    getMobile,
    getLanguage,
    getCurrentStupidPassword,
    setCurrentStupidPassword, getServerError, getGlobalLoader, setLanguage
} from "./common/features/user/settingsSlice";
import {isMobile} from "react-device-detect";

import MainPageMobile from "./mobile/features/main/MainPageMobile";

import LoginMobile from "./mobile/features/login/LoginMobile";
import LoginDesktop from "./desktop/features/login/LoginDesktop";

import BackgroundForModal from "./common/components/BackgroundForModal";
import TestBuyPageDesktop from "./desktop/features/tests/TestBuyPageDesktop";
import PanelMain from "./common/features/panel/PanelMain";
import {
    getBlackBackground,
    getVisibleConsultationModal,
    getVisibleErrorModal,
    getVisibleForgotPasswordModal,
    getVisibleLoginModal,
    getVisibleNewPasswordModal,
    getVisibleRegistrationModal,
    getVisibleSuccessModal,
    getVisibleWarningCancelModal,
    setBlackBackground,
    setVisibleLoginModal,
    setVisibleNewPasswordModal
} from "./common/features/application/applicationSlice";
import NewPasswordModal from "./common/components/NewPasswordModal";
import SuccessMessageModal from "./common/components/SuccessMessageModal";
import Profile from "./desktop/features/profile/Profile";
import RequireAuth from "./common/components/RequireAuth";
import {useScrollPosition} from "./common/hooks/useScrollPosition";
import EssayWorkPlace from "./common/features/panel/essayPlace/EssayWorkPlace";

import TopbarDesktop from "./desktop/components/TopbarDesktop";
import Footer from "./desktop/components/Footer";
import ConsultationModal from "./common/components/ConsultationModal";
import LanguageLevelTestWorkPlace from "./desktop/features/testPlace/languageLevelTest/LanguageLevelTestWorkPlace";

import Cat4List from "./common/features/cat4/Cat4List";
import Cat4ListTestPreparation from "./common/features/cat4/Cat4ListTestPreparation";

import BottomMobileBar from "./mobile/components/BottomMobileBar";
import TopbarMobile from "./mobile/components/TopbarMobile";
import LoginModal from "./common/components/LoginModal";
import RegistrationModal from "./common/components/RegistrationModal";
import ForgotPasswordModal from "./common/components/ForgotPasswordModal";
import WarningCancelModal from "./common/components/WarningCancelModal";
import TestResult from "./common/features/testWorkPlace/TestResult";
import Cat4MainResultTestDetailed from "./common/features/results/cat4/Cat4MainResultTestDetailed";
import Results from "./common/features/results/Results";
import LanguageLevelMainResultTestDetailed
    from "./common/features/results/languageLevel/LanguageLevelMainResultTestDetailed";
import {useLocation} from "react-router";
import LanguageRouteWrapper from "./common/components/LanguageRouteWrapper";
import {useTranslation} from "react-i18next";
import RootPageRedericet from "./common/components/RootPageRedericet";
import ArticleDetailed from "./common/features/article/ArticleDetailed";
import ServerError from "./common/features/error/ServerError";
import BigLoader from "./common/features/loader/BigLoader";
import RootPageRedirect from "./common/components/RootPageRedericet";
import PageNotFound from "./common/components/PageNotFound";
import {selectIsAuth} from "./common/features/auth/authSlice";

import Products from "./common/features/product/Products";
import ErrorMessageModal from "./common/components/ErrorMessageModal";
import CheckDetailed from "./common/features/check/CheckDetailed";
import Temp from "./desktop/features/Temp";
import ContactUs from "./common/components/ContactUs";

const App = () => {

    const dispatch = useDispatch();
    const visibleNewPasswordModal = useSelector(getVisibleNewPasswordModal);
    const blackBackground = useSelector(getBlackBackground);
    const visibleSuccessModal = useSelector(getVisibleSuccessModal);
    const visibleErrorModal = useSelector(getVisibleErrorModal);
    const visibleConsultationModal = useSelector(getVisibleConsultationModal);
    const visibleLoginModal = useSelector(getVisibleLoginModal);
    const visibleWarningCancelModal = useSelector(getVisibleWarningCancelModal);
    const visibleRegistrationModal = useSelector(getVisibleRegistrationModal);
    const visibleForgotPasswordModal = useSelector(getVisibleForgotPasswordModal);
    const globalLoader = useSelector(getGlobalLoader)

    useInitialization()
    const params = new URLSearchParams(window.location.search);
    const isAuth = useSelector(selectIsAuth)
    const resetPassword = params.get("resetPassword");
    const confirmPassword = params.get("confirmPassword");

    const {i18n } = useTranslation();
    const currentStupidPassword = useSelector(getCurrentStupidPassword)

    if(resetPassword && resetPassword.length > 0){
        if(!isAuth){
        dispatch(setVisibleNewPasswordModal(true))
        dispatch(setBlackBackground(true))
            }
    }
    if(confirmPassword && confirmPassword.length > 0){
      if(!isAuth){
          dispatch(setVisibleLoginModal(true))
          dispatch(setBlackBackground(true))
      }

    }

    const language = useSelector(getLanguage);

    useEffect(()=>{
        dispatch(setMobile(isMobile))
    }, [isMobile])

    useScrollPosition()
    const mobile = isMobile
    const stupidPassword = "adh68";
    const serverError = useSelector(getServerError)

    let content;
    //dispatch(setCurrentStupidPassword("adh68"))

   // if(currentStupidPassword !== stupidPassword) {
        if (false) {
            content = <div>
                <input value={currentStupidPassword}
                       onChange={(e) => dispatch(setCurrentStupidPassword(e.target.value))}/>
            </div>
        } else {
            if (serverError) {
                content = <ServerError/>
            } else {
                content = <div className="App">

                    <BrowserRouter>
                        <LanguageContext>
                            {mobile ? <TopbarMobile/> : <TopbarDesktop/>
                            }
                            <Routes>
                                {/* PUBLIC PAGES (Main page, information, description */}
                                <Route path='/' element={<RootPageRedirect/>}/>
                                <Route path={"/:languageCode/"} element={<LanguageRouteWrapper/>}>
                                    <Route path="articles/">
                                        <Route path=":alias" element={<ArticleDetailed/>}/>
                                    </Route>
                                    <Route index element={mobile ? <MainPageMobile/> : <MainPageDesktop/>}/>
                                    {
                                        /* <Route path="test-description/" >
                                             <Route index element={mobile ? <TestDescriptionListMobile /> : <TestDescriptionListDesktop /> }/>
                                             <Route path=":alias" element={mobile ? <TestDescriptionItemMobile /> :  <TestDescriptionItemDesktop /> }/>
                                         </Route>

                                         */
                                    }

                                    <Route path="temp/">
                                        <Route index element={<Temp/>}/>
                                    </Route>

                                    <Route path="products/">
                                        <Route index element={<Products/>}/>
                                        <Route path=":alias" element={<TestBuyPageDesktop/>}/>
                                    </Route>

                                    {/* LOGIN PAGE */}
                                    <Route path="login" element={mobile ? <LoginMobile/> : <LoginDesktop/>}/>
                                    <Route element={<RequireAuth/>}>
                                        {/* PANEL */}
                                        <Route path="panel/">
                                            <Route index element={<PanelMain/>}/>
                                            <Route path="profile" element={<Profile/>}/>
                                            <Route path="check" element={<CheckDetailed/>}/>
                                            <Route path="results/">
                                                <Route index element={<Results/>}/>
                                                <Route path="cat4/">
                                                    <Route path="detailed" element={<Cat4MainResultTestDetailed/>}/>

                                                </Route>
                                                <Route path="languageLevel/">
                                                    <Route path="detailed"
                                                           element={<LanguageLevelMainResultTestDetailed/>}/>
                                                </Route>

                                            </Route>

                                            <Route path="tests/">
                                                <Route path="cat4/">
                                                    <Route path="get" element={<Cat4List/>}/>
                                                    <Route path="start" element={<Cat4ListTestPreparation/>}/>
                                                    <Route path="example" element={<TestResult/>}/>

                                                </Route>
                                                <Route path="essay-work-place/" element={<EssayWorkPlace/>}/>
                                                <Route path="language-level-test/"
                                                       element={<LanguageLevelTestWorkPlace/>}/>
                                            </Route>
                                        </Route>
                                    </Route>

                                    <Route path='contact-us' element={<ContactUs/>} />
                                </Route>
                                <Route path='/error' element={<PageNotFound/>} status={404}/>

                                <Route path='*' element={<PageNotFound/>} status={404}/>
                            </Routes>

                            <Footer/>
                            {mobile && <BottomMobileBar/>}
                        </LanguageContext>
                    </BrowserRouter>
                    {visibleNewPasswordModal && <NewPasswordModal/>}
                    {blackBackground && <BackgroundForModal/>}
                    {visibleSuccessModal && <SuccessMessageModal/>}
                    {visibleConsultationModal && <ConsultationModal/>}
                    {visibleLoginModal && <LoginModal/>}
                    {visibleWarningCancelModal && <WarningCancelModal/>}
                    {visibleRegistrationModal && <RegistrationModal/>}
                    {visibleForgotPasswordModal && <ForgotPasswordModal/>}
                    {globalLoader && <BigLoader/>}
                    {visibleErrorModal && <ErrorMessageModal/>}
                </div>
            }

        }

    return content;
/*
* <Route element={<RequireAuth/>}>
                    <Route path="panel/">
                        <PanelMain />
                    </Route>
                  </Route>
* */
}

export default App;
