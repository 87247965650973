import {Link, useSearchParams} from "react-router-dom";
import {useGetResultByLanguageLevelResultIdQuery} from "../resultsSlice";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, getMobile, setGlobalLoader} from "../../user/settingsSlice";

import ItemQuestionResult from "../ItemQuestionResult";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import useToolService from "../../../hooks/useToolService";

import {Helmet} from "react-helmet-async";
import {KeyboardDoubleArrowLeft} from "@mui/icons-material";

const LanguageLevelMainResultTestDetailed = () => {
    const [searchParams] = useSearchParams()
    const entityId = searchParams.get("entityId");
    const { t } = useTranslation();
    const language = useSelector(getLocalizedLanguage)
    const {getTranslatedValue} = useToolService();
    const isMobile = useSelector(getMobile)
    const {data, isLoading, isSuccess, isError, error} = useGetResultByLanguageLevelResultIdQuery(entityId)
    ChartJS.register(ArcElement, Tooltip, Legend);
const dispatch = useDispatch()
    let content;

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    if(isSuccess){
         const numbersOfQuestions = data.totalQuestions;
        const correctAnswersCount = data.correctAnswers;
        const wrongAnswersCount = numbersOfQuestions - correctAnswersCount;

        let classLevel = "numberCorrectAnswers"
        switch(data.languageLevelFull){
            case "Beginner":
                classLevel += " beginnerColor";
                break;
            case "Elementary":
                classLevel += " elementaryColor";
                break;
            case "Pre-intermediate":
                classLevel += " preIntermediateColor";
                break;
            case "Intermediate":
                classLevel += " intermediateColor";
                break;
            case "Upper intermediate":
                classLevel += " upperIntermediateColor";
                break;
            case "Advanced":
                classLevel += " advancedColor";
                break;
        }

        const result = {
            labels: ['Верных', 'Неверных'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [correctAnswersCount, wrongAnswersCount],
                    backgroundColor: [
                        'rgba(60, 184, 120, 0.2)',
                        'rgba(229, 75, 66, 0.2)',


                    ],
                    borderColor: [
                        'rgba(60, 184, 120, 1)',
                        'rgba(229, 75, 66, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        let information = <><div className="resultTitleTopBlock">
            <div>
                <h1>{getTranslatedValue(data.titles)}</h1>

            </div>
            <div>
                <Link to={"/"+language+"/panel/results/"} className="linkToBack"><KeyboardDoubleArrowLeft/>{t("ToResults")}</Link>
            </div>

        </div>
        <div className="bigResultBlock">
            <div className="bigResultBlockTop">
                <h2>{t('YourLevel')}</h2>
                <p className={classLevel}>{data.languageLevelFull}</p>
                <h2>{t('CorrectAnswers')}</h2>
                <p className="numberCorrectAnswers">{correctAnswersCount}</p>
                <p>{t("FromNumberQuestions")} {numbersOfQuestions}</p>
                <div className="pieBlock">
                    <div className="pie">
                        <Pie  data={result}  />
                    </div>
                    <div className="percentageOfCorrects">
                        <div className="percentageBlock">
                            <span>{Math.round(((correctAnswersCount/numbersOfQuestions)*100))}%</span>
                            <span className="successSmall">{t("SuccessP")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div></>;
        if(isMobile){
            information = <>
                <div className="textAlignRight">
                    <Link to={"/"+language+"/panel/results/"} className="linkToBack"><KeyboardDoubleArrowLeft/>{t("ToResults")}</Link>
                </div><br/>
                <div>
                    <h1>{getTranslatedValue(data.titles)}</h1>

                </div>
                <div className="bigResultBlockTop">
                    <h2>{t('CorrectAnswers')}</h2>
                    <p className="numberCorrectAnswers">{correctAnswersCount}</p>

                    <p>{t("FromNumberQuestions")} {numbersOfQuestions}</p><br/><br/>
                    <div className="percentageOfCorrects">
                        <div className="percentageBlock">
                            <span>{Math.round(((correctAnswersCount/numbersOfQuestions)*100))}%</span>
                            <span className="successSmall">{t("SuccessP")}</span>
                        </div>
                    </div><br/><br/>
                    <div className="pie">
                        <Pie  data={result}  />
                    </div><br/><br/>
                </div>
            </>
        }

        content = <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide">
                    {information}
                    <br/>
                    <h2>{t("Transcription")}</h2>
                    {content = data.languageLevelAnswerResultQuestionDtoList.map((item, index) => <ItemQuestionResult key={index} index={index} item={item} />)}
                </div>
            </div>
        </div>
    }

    if(isError){
        content = "";
    }

    return <><Helmet>
        <title>{isSuccess ? t("Result")+": "+getTranslatedValue(data.titles): "Many Tests"}</title>
        <meta name='description' content="Panel" />
    </Helmet>
        {content}
    </>
    ;


}

export default LanguageLevelMainResultTestDetailed;