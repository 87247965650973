import MainProductItem from "../../../common/features/product/MainProductItem";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getMobile} from "../../../common/features/user/settingsSlice";
import React from "react";

const MainPageTestCatalog = ({data, removeTitle}) => {
    const isMobile = useSelector(getMobile)
    const { t } = useTranslation();
    const dateTime = Date.now();

    return <div className={isMobile ? "" : "leftSideBlock"}>
        <div className="classicalFlex testMore">
            {!removeTitle &&  <span>{t('LastCatalog')}</span> } {
            //<span>{t('ViewAll')} <ArrowRightAlt /></span>
        }

        </div>
        <div className="testList">
            {data.map(product=><MainProductItem dateTime={dateTime} key={product.id} item={product}/>)}
        </div>
        <hr/>
    </div>

}

export default MainPageTestCatalog;