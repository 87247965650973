import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {useGetMyOrdersQuery} from "../order/ordersApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {getLocalizedLanguage, getMobile} from "../user/settingsSlice";
import ArchiveItem from "../archive/ArchiveItem";
import {useTranslation} from "react-i18next";
import {selectIsAuth} from "../auth/authSlice";
import {setBlackBackground, setVisibleLoginModal} from "../application/applicationSlice";
import {useGetFreeMutation} from "../../../desktop/features/purchase/purchaseApiSlice";
import {Helmet} from "react-helmet-async";
import useToolService from "../../hooks/useToolService";

const LanguageLevelTestButton = ({productObject, alias}) => {
    const language = useSelector(getLocalizedLanguage)
    const isMobile = useSelector(getMobile)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isAuth = useSelector(selectIsAuth);
    const {preparingUrlForNavigation} = useToolService();
    const contentClickHandler = (e) => {
        navigate(preparingUrlForNavigation(e));
    };
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMyOrdersQuery();
    const [getFree] = useGetFreeMutation();
    const navigate = useNavigate();
    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }



    const scroll = async () => {
        try{
           const res = await getFree({priceId: productObject.prices[0].id, productId: productObject.id, total: productObject.prices[0].value, country: "", paymentSystem: ""}).unwrap();
           if(res){
               navigate("/"+language+"/panel/tests/language-level-test/?orderId="+res.orderId+"&entityId="+res.product.languageLevelEntities[0])
           }

        }catch (e){
            console.log(e)
        }
    }
    let content;

        if(isSuccess){
            const res = data.flatMap((order=>{return {orderId: order.id, orderDate: order.date, ...order.product}})).filter(test=>test.alias==="english-placement-test");

            if(res.length>0){
                const test = res[0];
                content = <div className="aloneButtonCenter"><Link className="greenButton linkAsButtonSimple roundedButton" to={"/"+language+"/panel/tests/language-level-test/?orderId="+test.orderId+"&entityId="+test.languageLevelTests[0].id}>{t('StartTest')}</Link></div>
            }else{
                content = isAuth ?  <div className="aloneButtonCenter"><button onClick={()=>{scroll()}} className="greenButton roundedButton">{t('StartTest')}</button></div> : <div className="aloneButtonCenter"><button onClick={()=>{openLoginModal()}} className="greenButton roundedButton">{t('StartTest')}</button></div>
            }
        }else{
            content = <div className="aloneButtonCenter"><button onClick={()=>{openLoginModal()}} className="greenButton roundedButton">{t('StartTest')}</button></div>;
        }


   return <div className="aloneButtonCenter">{content}</div>




}

export default LanguageLevelTestButton;