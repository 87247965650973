import {useSearchParams} from "react-router-dom";
import {useCheckCat4Mutation, useGetCat4TestQuery} from "./cat4Slice";
import {useEffect} from "react";
import TestWorkPlace from "../testWorkPlace/TestWorkPlace";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentQuestionId, setFirstQuestionId, setNumberOfQuestions} from "../commonTestData/testProgressLine";
import {getLocalizedLanguage, getMobile, setGlobalLoader} from "../user/settingsSlice";
import React from "react";
import {Helmet} from "react-helmet-async";
import useToolService from "../../hooks/useToolService";
import {getLastUrl, getTestStatus, setTestStatus} from "../application/applicationSlice";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import {KeyboardDoubleArrowLeft} from "@mui/icons-material";


const Cat4ListTestPreparation = () => {
    const { t } = useTranslation();
    const {getTranslatedValue} = useToolService();
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    const orderId = searchParams.get("orderId");
    const entityId = searchParams.get("entityId");
    const {data, isLoading, isSuccess, isError, error} = useGetCat4TestQuery({orderId: orderId, entityId: entityId})
    const isMobile = useSelector(getMobile)
    const status = useSelector(getTestStatus)
    const lastUrl = useSelector(getLastUrl)

    const [checkCat4Api] = useCheckCat4Mutation()
    let content;
    const language = useSelector(getLocalizedLanguage)
    useEffect(()=>{
        if(isSuccess){
            const firstId = data.questions[0].id;
            dispatch(setNumberOfQuestions(data.questions.length))
            dispatch(setCurrentQuestionId(firstId))
            dispatch(setFirstQuestionId(firstId))
        }
    },[data, isSuccess])

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    if(status === "await" && isSuccess){
        const currentDescription = getTranslatedValue(data.descriptions);
        const newDescription = currentDescription.replace("{{TIME}}", (data.time/60)+" "+t("Minutes1")).replace("{{LENGTH}}", data.questions.length)
        content = <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="articleMainBlock bottomM">
                    <p className="linkToBackBlock"><Link className="linkToBack" to={"/"+language+"/panel/tests/cat4/get?orderId="+orderId+"&entityId=0"}><KeyboardDoubleArrowLeft/>{t("To1")+" CAT4"}</Link></p>
                    <div dangerouslySetInnerHTML={{__html: newDescription}}/>
                    <div className="centralBlock ">
                        <button className="infoButton roundedButton bigButton" onClick={()=>{dispatch(setTestStatus("started"))}}>Start</button>
                    </div>
                </div>

            </div>

        </div>

    }
    if(status === "started" && isSuccess){

        content = <TestWorkPlace
            data={data}
            url={'/'+language+"/panel/results/cat4/detailed?entityId="}
            api={checkCat4Api}
            orderId={orderId}
            time={data.time}
            list={data.questions}
            title={getTranslatedValue(data.titles)+" | "+data.category+" | "+data.sample+" | "+data.pack }
        />

    }



    return <div>
        <Helmet>
            <title>{isSuccess ? getTranslatedValue(data.titles)+" | "+data.category+" | "+data.sample+" | "+data.pack : "Many Tests"}</title>
            <meta name='description' content="Panel" />
        </Helmet>
                {content}

    </div>
}

export default Cat4ListTestPreparation;