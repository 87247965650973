import {useDispatch, useSelector} from "react-redux";
import {addAnswer, getAnswerByQuestion} from "../commonTestData/testAnswersSlice";
import {getMobile} from "../user/settingsSlice";
import {CheckCircle, Dangerous} from "@mui/icons-material";
import React from "react";

const PictureOptionResult = ({options}) => {
     const isMobile = useSelector(getMobile)
    let questionAnswersSide = "questionAnswersSideResult";

    if(isMobile){
        questionAnswersSide = "questionAnswersSideResult questionAnswersSideResultMobile";
    }

    return <div className={questionAnswersSide}>
            {

                options.map((option,index)=>{

                    let resultClassBlock;
                    let icon;
                    if(option.correct){
                        resultClassBlock = "imageOptionSelectResultCorrect";
                        icon = <CheckCircle className="resultIconPicture correctIcon" />
                    }else if(option.answered && !option.correct){
                        resultClassBlock = "imageOptionSelectResultWrong";
                        icon = <Dangerous className="resultIconPicture wrongIcon"/>
                    }
                    else{
                        resultClassBlock = "imageOptionSelectResultStandard";
                        icon = <div className="iconStub"></div>
                    }


                    return <div className="imageOptionSelectResultBlock" key={index}><div >
                        <span>{index+1}:</span>
                        <div className={resultClassBlock} >
                            <div dangerouslySetInnerHTML={{__html: option.title}}/>
                        </div>

                    </div>
                        {
                            icon
                        }
                    </div>
                })}


    </div>
}

export default PictureOptionResult;