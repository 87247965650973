import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {persistor, store} from "./common/app/store";
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import { hydrateRoot } from 'react-dom/client';
import {render} from "react-dom";
import BigLoader from "./common/features/loader/BigLoader";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <HelmetProvider>
                    <Suspense fallback={<BigLoader/>}>

                        <App />
                    </Suspense>
                </HelmetProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);



/*

const domNode = document.getElementById('root');
const root = hydrateRoot(domNode, <React.StrictMode>
    <Provider store={store} >
        <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider>
                <Suspense fallback="loading">
                    <App />
                </Suspense>
            </HelmetProvider>
        </PersistGate>
    </Provider>
</React.StrictMode>);
*/



reportWebVitals();
