import TestNumberLineMainLineItem from "./TestNumberLineMainLineItem";
import {useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import React from "react";
const TestNumberLines = ({data}) => {
    const isMobile = useSelector(getMobile)
    return <div className={isMobile ?"testNumberLineMainBlock testNumberLineMainBlockMobile" : "testNumberLineMainBlock"}>
        {data.map((el, i) =>
        {
           return <TestNumberLineMainLineItem data={el} i={i} key={el.id} />
        }

        )}
    </div>
}

export default TestNumberLines;