import {useStopwatch, useTimer} from 'react-timer-hook';
import React, {useEffect} from "react";
import {getFullSeconds, setHours, setMinutes, setSeconds} from "../features/commonTestData/timerSlice";
import {useDispatch, useSelector} from "react-redux";

const MyStopwatch = ({time, d, h, m, s, callback}) => {
    const dispatch = useDispatch()

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });

    const digitsToStyle = (digs) =>{
        const leftDigit = digs >= 10 ? digs.toString()[0] : '0';
        const rightDigit = digs >= 10 ? digs.toString()[1] : digs.toString();
        return leftDigit+""+rightDigit
    }

    useEffect(()=>{

        dispatch(setHours(hours));
        dispatch(setMinutes(minutes));
        dispatch(setSeconds(seconds));

    },[hours, minutes, seconds ])



    return <div >
        {d && <span>{days}:</span>}{h &&<span>{digitsToStyle(hours)}:</span>}{m && <span>{digitsToStyle(minutes)}:</span>}{s &&<span>{digitsToStyle(seconds)}</span>}
    </div>
}
export default React.memo(MyStopwatch);