import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getOfferAccepted, getOfferLink, setOfferAccepted, setOfferLink} from "./offerCheckboxBlockSlice";
import {getLocalizedLanguage, getMobile} from "../user/settingsSlice";
import {useEffect} from "react";

const OfferCheckboxBlock = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const offerAccepted = useSelector(getOfferAccepted);
    const offerLink = useSelector(getOfferLink);
    const language = useSelector(getLocalizedLanguage)

    useEffect(()=>{
        if(language === "ru"){
            dispatch(setOfferLink("/offer-ru.pdf"))
        }else{
            dispatch(setOfferLink("/offer-en.pdf"))
        }

    },[language])

    return <div className="payBlock acceptOffer">
        <div>
            <input checked={offerAccepted} onChange={()=>{dispatch(setOfferAccepted(!offerAccepted))}} type="checkbox" id="payButton"/>
            <label htmlFor="payButton" className="fas fa-bars">{t("AcceptOfferText.part1")}<Link target="_blank" to={offerLink}>{t("AcceptOfferText.part2")}</Link></label>
        </div>
    </div>


}

export default OfferCheckboxBlock;