import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {GET_MY_ORDERS, GET_ORDER, UPLOAD_FILE} from "../../app/repository";
import {apiSlice} from "../../app/api/apiSlice";
import {userApiSlice} from "../user/userApiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMyOrders: builder.query({
            query: () => GET_MY_ORDERS,
            providesTags: (result = [], error, arg) => [
                'Orders',
                ...result.map(({ id }) => ({ type: 'Orders', id }))
            ],
        }),
        getOrder: builder.mutation({
            query: (formData) => {

                return {
                    url: GET_ORDER,
                    method: 'POST',
                    body: formData,
                }
            },
            invalidatesTags:  ['Orders']
        }),
    })
})

export const {
    useGetMyOrdersQuery,
    useGetOrderMutation
} = ordersApiSlice