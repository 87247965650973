import {apiSlice} from "../../app/api/apiSlice";
import {GET_ARTICLE_BY_ALIAS, GET_ARTICLES, GET_SHORT_ARTICLES} from "../../app/repository";

export const articleSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getArticleByAlias: builder.query({
            query: (alias) => {
                return GET_ARTICLE_BY_ALIAS+alias},
            providesTags: (result, error, arg) => [{ type: 'Articles', id: arg }]
        }),
        getArticles: builder.query({
            query: (alias) => {
                return GET_ARTICLES
            },
            providesTags: (result, error, arg) => [{ type: 'Articles', id: arg }]
        }),
        getShortArticles: builder.query({
            query: (alias) => {
                return GET_SHORT_ARTICLES
            },
            providesTags: (result, error, arg) => [{ type: 'Articles', id: arg }]
        }),
    })
})

export const {
    useGetShortArticlesQuery,
    useGetArticlesQuery,
    useGetArticleByAliasQuery
} = articleSlice