import {useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, getMobile} from "../../../common/features/user/settingsSlice";

import React from "react";

import {useGetShortArticlesQuery} from "../../../common/features/article/articleSlice";
import useToolService from "../../../common/hooks/useToolService";
import {Link} from "react-router-dom";
const TestTracker = () => {

    const isMobile = useSelector(getMobile)
    const language = useSelector(getLocalizedLanguage)
    const {getTranslatedValue} = useToolService()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetShortArticlesQuery();

    let content;
    if(isSuccess){
        content = data.map((article=><div key={article.alias} className="lastTrackerInformationItem">
            <Link to={"/"+language+"/articles/"+article.alias}>{getTranslatedValue(article.titles)}</Link>
        </div>))
    }
    return <div className={isMobile ? "wrapperInsideMobile ArticlesListMobile" : "lastTrackerInformationBlock rightSideBlock"}>
        <div><img src="/img/testTrackerImage.png" alt={"Articles"} /></div>
        <div className="lastTrackerInformation">
            <div className="lastTrackerInformationItem">
                <h2>CAT4</h2>

            </div>
            {content}

        </div>
    </div>
}

export default TestTracker;