import MainProductItem from "../../../common/features/product/MainProductItem";
import {useTranslation} from "react-i18next";
import React from "react";
const MainPageTestCatalog = ({data, removeTitle}) => {
    const { t } = useTranslation();
    const dateTime = Date.now();

    return <div className="wrapperInsideMobile">
        <div className="testMore ">
            {!removeTitle && <span>{t('LastCatalog')}</span>} {
            //<span>{t('ViewAll')} <ArrowRightAlt /></span>
        }

        </div>
        <div className="mobileCenter">
            <div className="testListMobile">
                {data.map(product=><MainProductItem dateTime={dateTime} key={product.id} item={product}/>)}
            </div>
        </div>

        <hr/>
    </div>

}

export default MainPageTestCatalog;