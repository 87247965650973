import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getMobile} from "../user/settingsSlice";
import React from "react";
const ServerError = () => {
    const { t } = useTranslation();
    const isMobile = useSelector(getMobile)

    return <div className="serverErrorBlock" style={{backgroundImage: isMobile ? "url(/img/backgroundErrorServerPageMobile.webp)" : "url(/img/backgroundErrorServerPage.webp)"}}>
        <div className="centralErrorBlock">
            <h1>{t("GetConsultationRecommendation.part1")}</h1>
            <h2>{t("SendRequestError.part1")}</h2>
            <p>{t("ServerErrorLastMessage")}<a href="mailto:admin@many-tests.com">admin@many-tests.com</a></p>
        </div>

    </div>

}

export default ServerError;