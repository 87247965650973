import {apiSlice} from "../../app/api/apiSlice";
import {
    CHECK_LANGUAGE_LEVEL_TEST,
    GET_LANGUAGE_TEST
} from "../../app/repository";

export const languageLevelTestSliceApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLanguageLevelTest: builder.query({
            query: ({entityId,orderId}) => {

                return GET_LANGUAGE_TEST+"?entityId="+entityId+"&"+"orderId="+orderId},
            providesTags: (result, error, arg) => [{ type: 'LanguageLevelTests', id: arg }]

        }),
        checkLanguageLevelTest: builder.mutation({
            query: ({body, orderId}) => ({
                url: CHECK_LANGUAGE_LEVEL_TEST+orderId,
                method: 'POST',
                body: body
            }),
            invalidatesTags:  ['Results']

        }),


    })
})

export const {
    useGetLanguageLevelTestQuery,
    useCheckLanguageLevelTestMutation
} = languageLevelTestSliceApi