import {useSearchParams} from "react-router-dom";
import TopbarDesktop from "../../../../desktop/components/TopbarDesktop";
import React, {useEffect} from "react";
import {useGetEssayConversationQuery} from "../../essay/essayConverstionSlice";

import EssayWorkPlaceInside from "./EssayWorkPlaceInside";
import BallLoader from "../../../components/BallLoader";
import {Helmet} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../../user/settingsSlice";

const EssayWorkPlace = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    const orderId = searchParams.get("orderId");
    const entityId = searchParams.get("entityId");
    const isMobile = useSelector(getMobile)
    const {data, isLoading, isSuccess, isError, error} = useGetEssayConversationQuery({entityId: entityId, orderId: orderId})


    let globalContent = "";

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])
    if(isSuccess){

      globalContent = <EssayWorkPlaceInside data={data.essayConversation } price={data.price} />
    }

    if(isError){
        if(error.status === 400){
            /*createEssayConversation({entityId: entityId, orderId: orderId})
                .unwrap() */
        }
    }


    return <div>

        <div  className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className={isMobile ? "buyCardInsideWide" : "buyCardInside"}>
                    {globalContent}
                </div>
            </div>

        </div>
    </div>
}

export default EssayWorkPlace;