import React from "react";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";
import {
    getScrollPosition,

    getVisibleLoginModal, setBlackBackground,

    setVisibleLoginModal,
} from "../features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import InputEmail from "./UI/InputEmail";
import InputPassword from "./UI/InputPassword";
import useToolService from "../hooks/useToolService";
import {useEffect, useState} from "react";
import {useConfirmationMutation, useLoginMutation, useRegistrationMutation} from "../features/auth/authApiSlice";
import {getLanguage} from "../features/user/settingsSlice";
import {
    selectEmail,
    selectIsEmailValid,
    selectIsPasswordValid,
    selectPassword,
    setConfirmPassword,
    setCredentials,
    setEmailError,
    setIsEmailValid,
    setIsFirstnameValid, setIsLastnameValid,
    setIsPasswordValid,
    setPassword,
    setPasswordError
} from "../features/auth/authSlice";

const LoginModal = () => {
    const [statusLoader, setStatusLoader] = useState(false)
    const visibleLoginModal = useSelector(getVisibleLoginModal);
    const email = useSelector(selectEmail);
    const password = useSelector(selectPassword);
    const isEmailValid = useSelector(selectIsEmailValid)
    const isPasswordValid = useSelector(selectIsPasswordValid)
    const [loginApi] = useLoginMutation();
    const dispatch = useDispatch();
    const language = useSelector(getLanguage)
    const [successMessage, setSuccessMessage] = useState(false)
    const { t } = useTranslation();

    const params = new URLSearchParams(window.location.search);
    const confirmPassword = params.get("confirmPassword");
    const [confirm] = useConfirmationMutation();

    useEffect(()=>{
       if(confirmPassword && confirmPassword.length !== 0){
            confirm(confirmPassword).then(result=>{
                setSuccessMessage(result.data)

            }).catch((error)=>{

            })
        }
    },[confirmPassword])

    const {registrationHandler, forgotPasswordHandler} = useToolService()

    const login = () => {

        if(email.length === 0){
            dispatch(setEmailError(t("InvalidEmptyField")))
            dispatch(setIsEmailValid(false));
        }
        if(password.length === 0){
            dispatch(setPasswordError(t("InvalidEmptyField")))
            dispatch(setIsPasswordValid(false))
        }

        if(isEmailValid && isPasswordValid){
            const object = {password: password, email: email, language: language}
            setStatusLoader(true)
            loginApi(object).unwrap().then(result=>{
                dispatch(setCredentials(result))
                setStatusLoader(false)
                dispatch(setVisibleLoginModal(false))
                dispatch(setIsFirstnameValid(true))
                dispatch(setIsLastnameValid(true))
                dispatch(setIsEmailValid(true))
                dispatch(setBlackBackground(false))

            }).catch(error=>{
                setStatusLoader(false)

                switch (error.status){
                    case 423:
                        dispatch(setPasswordError(t("YouAreBanned")))
                        dispatch(setIsPasswordValid(false));
                    break;
                    case 403:
                        dispatch(setPasswordError(t("WrongEmailPassword")))
                        dispatch(setIsPasswordValid(false));
                    break;
                    case 412:
                        dispatch(setPasswordError(t("NotCompletedRegistration")))
                        dispatch(setIsPasswordValid(false));
                    break;
                }
            })
            dispatch(setPassword(""))
            dispatch(setConfirmPassword(""))
        }

    }

    return <div style={{display: visibleLoginModal ? "block" : "none"}}>
        <WhiteBackgroundForModal modalTitle={t("Login")} modalDescription={t("LoginModalDescription")} close={()=>dispatch(setVisibleLoginModal(false))}>
            <div className="formInputs">
                {confirmPassword && <div className="successColor successMessageSmall">{t('SuccessConfirm')}</div>}
                <InputEmail classes="modalInput"/>
                <InputPassword classes="modalInput"/>
                <button disabled={(!(isEmailValid && isPasswordValid)) || statusLoader} onClick={()=>login()} type="button" className="modalInput redButton modalButtons flexCenterCenter">{t('Login')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                <div className="loginModalBottomLinksBlock">
                    <div className="registrationLinkModal" onClick={()=>registrationHandler()}>{t("Registration")}</div>
                    <div className="registrationLinkModal" onClick={()=>forgotPasswordHandler()}>{t("ForgotPassword")}</div>
                </div>
            </div>

        </WhiteBackgroundForModal>
    </div>
}

export default LoginModal;