import React from "react";
import {useSelector} from "react-redux";
import {getGlobalLoader} from "../user/settingsSlice";
import {BallTriangle} from "react-loader-spinner";

const BigLoader = () => {
    const globalLoader = useSelector(getGlobalLoader)

    return <div className="loaderBlockMain" style={{display: globalLoader ? "block" : "none"}}>
        <div className="loaderBlockMainInside horizontalLoader"><BallTriangle
            height={80}
            width={80}
            radius={5}
            color="#e54b42"
            ariaLabel="ball-triangle-loading"
            wrapperClass={"centralLoader"}
            wrapperStyle=""
            visible={true}
        /></div>
    </div>
}

export default BigLoader;