import {useEffect, useState} from "react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFirstName, selectFirstNameError,
    selectLastName,
    selectLastNameError,
    setFirstname,
    setFirstnameError,
    setIsFirstnameValid
} from "../../features/auth/authSlice";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../features/user/settingsSlice";
import useToolService from "../../hooks/useToolService";

const InputFirstname = ({classes}) => {
    const firstName = useSelector(selectFirstName);
    const { t } = useTranslation();
    const language = useSelector(getLanguage)
    const dispatch = useDispatch();

    const {isTooBig} = useToolService()
    const firstNameError = useSelector(selectFirstNameError)
    

    useEffect(()=>{

        if(firstName.length !== 0) {
            if (isTooBig(firstName)) {
                dispatch(setFirstnameError(t("TooBig")))
                dispatch(setIsFirstnameValid(false))
            } else {
                dispatch(setFirstnameError(""))
                dispatch(setIsFirstnameValid(true))
            }
        }else{
            dispatch(setFirstnameError(""))
            dispatch(setIsFirstnameValid(false))
        }
    }, [firstName, language])

    return  <div className="inputDivWithMessage ">
        <input className={firstNameError.length>0 ? "redBorder "+classes : classes} type="text" value={firstName} placeholder={t('Name')} onChange={(e)=>dispatch(setFirstname(e.target.value))} />
        <div>{firstNameError}</div>
    </div>
}

export default InputFirstname;