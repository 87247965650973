import TestTracker from "../testDescription/TestTracker";
import MainPageTestCatalog from "../tests/MainPageTestCatalog";
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import StartPracticing from "./StartPracticing";
import {useGetAllProductsQuery} from "../../../common/features/product/productsSlice";
import {getLocalizedLanguage, setGlobalLoader} from "../../../common/features/user/settingsSlice";
import {useDispatch, useSelector} from "react-redux";
import useToolService from "../../../common/hooks/useToolService";
import {useSearchParams} from "react-router-dom";
import {setVisibleSuccessModal} from "../../../common/features/application/applicationSlice";
import {useNavigate} from "react-router";

const MainPageDesktop = ()  => {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery();
    const dispatch = useDispatch()


    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])
    const {getLanguageLinksForPages, prepareMeta} = useToolService();
    return  <div>
        <Helmet   link={getLanguageLinksForPages("/")}
                  meta={prepareMeta("Many Tests: Essay, Test", "Many Tests: Essay, Test", "website", "/", "")}
                  title={"Many Tests: Essay, Test"}
        />

        <div className="actionsTests wrapper">
            {isSuccess && <StartPracticing data={data} />}

            <TestTracker />

        </div>
        <div className="classicalFlex wrapper mainTestsCatalogSub">
            {isSuccess && <MainPageTestCatalog data={data} />}
            {
                //<SubscribeSide/>
            }
        </div>
        {/*<div className="classicalFlex wrapper mainTestsCatalogSub">
            <div className="leftSideBlock"></div>
            <TestDates />
        </div>*/}

    </div>
}

export default MainPageDesktop;