import {useGetAllProductsQuery} from "../../../common/features/product/productsSlice";
import {Helmet} from "react-helmet-async";
import StartPracticing from "../../../desktop/features/main/StartPracticing";
import TestTracker from "../../../desktop/features/testDescription/TestTracker";
import MainPageTestCatalog from "../../../desktop/features/tests/MainPageTestCatalog";
import MainPageTestCatalogMobile from "../tests/MainPageTestCatalogMobile";
import {setGlobalLoader} from "../../../common/features/user/settingsSlice";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import useToolService from "../../../common/hooks/useToolService";
const MainPageMobile = ()  => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery();
    const dispatch = useDispatch()
    const {getLanguageLinksForPages, prepareMeta} = useToolService();

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    },[isLoading, isSuccess])



    return  <div>
        <Helmet   link={getLanguageLinksForPages("/")}
                  meta={prepareMeta("Many Tests: Essay, Test", "Many Tests: Essay, Test", "website", "/", "")}
                  title={"Many Tests: Essay, Test"}
        />

        <div className="wrapperMobile">
            {isSuccess && <StartPracticing data={data} />}


        </div>
        <div className="wrapperMobile mainTestsCatalogSub">
            {isSuccess && <MainPageTestCatalogMobile data={data} />}
            {
                //<SubscribeSide/>
            }
        </div>
        <div>
            <TestTracker />
        </div>
        {/*<div className="classicalFlex wrapper mainTestsCatalogSub">
            <div className="leftSideBlock"></div>
            <TestDates />
        </div>*/}

    </div>
}

export default MainPageMobile;