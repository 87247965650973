import TopbarDesktop from "../../../desktop/components/TopbarDesktop";
import Footer from "../../../desktop/components/Footer";
import {useGetMyOrdersQuery} from "../order/ordersApiSlice";
import ArchiveItem from "../archive/ArchiveItem";
import {useGetAllEssayConversationsFinishedQuery} from "../essay/essayConverstionSlice";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import Packs from "../pack/Packs";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, getMobile, setGlobalLoader} from "../user/settingsSlice";
import {useTranslation} from "react-i18next";
import React from "react";
import {Link} from "react-router-dom";
const PanelMain = () => {
    const isMobile = useSelector(getMobile)
    const [packs, setPacks] = useState([])
    const {t} = useTranslation();
    const language = useSelector(getLocalizedLanguage)
    let testListPanelList = "testListPanelList";
    if(isMobile){
        testListPanelList = "testListPanelListMobile";
    }
 const dispatch = useDispatch()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMyOrdersQuery();

   // const [activeEssays, setActiveEssays] = useState([])
   // const [notActiveEssays, setNotActiveEssays] = useState([])

    const {
        data: dataConversation,
        isLoading: isLoadingConversation,
        isSuccess: isSuccessConversation,
        isError: isErrorConversation,
        error: errorConversation
    } = useGetAllEssayConversationsFinishedQuery()

    const workWithEssays = (activity) => {

        return data.flatMap((order=>{

            return {orderId: order.id, price: order.price, orderDate: order.date, ...order.product}})).flatMap(product=>{

                return product.essayList.filter(essay=>{

                    const essayConversation = dataConversation.filter(target=> (target.order.id === product.orderId && target.essay.id === essay.id));

                    if(essayConversation.length>0){
                        if(!activity){
                            return essayConversation[0].status === "done"
                        }else{
                            return essayConversation[0].status !== "done"
                        }

                    }
                    /*else{
                        return activity !== false;
                    }*/
                }).flatMap(((essay)=>{

                    const essayConversation = dataConversation.filter(target=> (target.order.id === product.orderId && target.essay.id === essay.id));
                    return <ArchiveItem alias="tests/essay-work-place/" item={{type: "essay", status: essayConversation[0].status, orderDate: product.orderDate, price: product.price, orderActive: !activity, orderId: product.orderId, productId: product.id, alias: product.alias, ...essay}} key={product.orderId} />
                }))
        })
    }
   // console.log(data)
    const workWithLanguageTests = () => {
        return data.flatMap((order=>{
            return {orderId: order.id, orderDate: order.date, ...order.product}})).flatMap(product=>{
            return product.languageLevelTests.flatMap(((languageLevelTest)=>{
                  return <ArchiveItem alias="tests/language-level-test/" item={{type: "languageTest",status: true, orderDate: product.orderDate, orderActive: false, orderId: product.orderId, productId: product.id,...languageLevelTest}} key={product.orderId} />
            }))
        })
    }
    const workWithPacks = () => {
        return data.flatMap((order=>{
            return {orderId: order.id, orderDate: order.date, ...order.product}})).flatMap(product=>{

            return product.packs.flatMap(((pack)=>{
                return {picture: product.picture, titles: product.titles, status: true, orderDate: product.orderDate,orderId: product.orderId, productId: product.id, ...pack}
                //return <ArchiveItem alias="packs" item={{status: true, orderDate: product.orderDate, orderActive: false, orderId: product.orderId, productId: product.id,...pack}} key={product.orderId} />
            }))
        })

    }


    useEffect(()=>{
        if(isSuccess){

            setPacks(workWithPacks())
        }
    },[isSuccess])

    let activeEssays = [];
    let notActiveEssays =[];
    let activeTests = [];


    if(isSuccess && isSuccessConversation){
        activeEssays = workWithEssays( true)
        notActiveEssays = workWithEssays( false)
        activeTests = workWithLanguageTests()
    }

    useEffect(()=>{
        if(isLoading || isLoadingConversation){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess && isSuccessConversation){
            dispatch(setGlobalLoader(false))
        }
    },[isLoading,isLoadingConversation, isSuccess, isSuccessConversation ])

    let content;

    if(activeTests.length === 0 && activeEssays.length === 0 && packs.length === 0){
        content = <div className="noProductsMainBlock"><p>{t("NoProducts.part1")}</p><p>{t("NoProducts.part2")}</p><div className="noProductsMainBlockToCatalog"><Link className="bigButton linkAsButtonSimple redButton roundedButton" to={"/"+language+"/products/"}>{t("Catalog")}</Link></div></div>
    }else{
          content = <>{(activeTests.length > 0 || packs.length > 0) && <div className="testListPanel">
                <h2>{t("Tests")}</h2>
                <div className={testListPanelList}>
                    {activeTests}

                    {packs.length > 0 && <Packs data={packs} />}
                </div>

            </div>
        }
        {activeEssays.length > 0 && <div className="testListPanel">
            <h2>{t("Essay")}</h2>
            <div className={testListPanelList}>
                {activeEssays}
            </div>

        </div>
        }
        </>
    }

    return <div>
        <Helmet>
            <title>Panel</title>
            <meta name='description' content="Panel" />
        </Helmet>
            <div className={"wrapper buyCard"}>
                <div className={"buyCardInsidePanel"}>
                    <div className="buyCardInsidePanelLeft">
                        {content}
                    </div>
                    <div className="buyCardInsidePanelLeft">
                        {notActiveEssays.length > 0 && <div className="testListPanel">
                            <h2>{t("Archive")}</h2>
                            <div className="testListPanelList">
                                {notActiveEssays}
                            </div>

                        </div>
                        }
                    </div>

                </div>
            </div>

    </div>
}

export default PanelMain;