import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    hours: 0,
    minutes: 0,
    seconds: 0,
    visible: false,
    totalTime: 0
}

const timerSlice = createSlice({
        name: '@@timerSlice',
        initialState: initialState,
        reducers: {
            setHours:(state, action)=>{
                state.hours = action.payload;
            },
            setTotalTime:(state, action)=>{
                state.totalTime = action.payload;
            },
            setMinutes:(state, action)=>{
                state.minutes = action.payload;
            },
            setSeconds:(state, action)=>{

                state.seconds = action.payload;
            },
            setVisible:(state, action)=>{
                state.visible = action.payload;

            },

        },



    }
)

export const {setTotalTime,setHours, setMinutes, setSeconds, setVisible} = timerSlice.actions;
export const timerReducer = timerSlice.reducer;
export const getHours = (state) => state.timerSlice.hours
export const getMinutes = (state) => state.timerSlice.minutes
export const getSeconds = (state) => state.timerSlice.seconds
export const getTotalTime = (state) => state.timerSlice.totalTime
export const getVisible = (state) => state.timerSlice.visible
export const getFullSeconds = (state) => {

    let fullSeconds = state.timerSlice.seconds;
    const minutes = state.timerSlice.minutes
    const hours = state.timerSlice.hours

    if(minutes > 0){
        fullSeconds += minutes*60;
    }
    if(hours > 0){
        fullSeconds += hours*3600
    }

    return fullSeconds;
}
export const getFullTimer = state => ({hours: state.timerSlice.hours, minutes: state.timerSlice.minutes, seconds: state.timerSlice.seconds})

