import {Outlet} from "react-router-dom";
import React from "react";
const DesktopBufferOutlet = () => {

    return <div>
                <Outlet/>
            </div>


}

export default DesktopBufferOutlet;