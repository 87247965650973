import {apiSlice} from "../../app/api/apiSlice";
import {CREATE_ESSAY_MESSAGE} from "../../app/repository";

export const essayMessageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createEssayMessage: builder.mutation({
            query: (formData) => {

                return {
                    url: CREATE_ESSAY_MESSAGE,
                    method: 'POST',
                    body: formData,
                }

            }
        }),
    })
})

export const {
    useCreateEssayMessageMutation
} = essayMessageSlice