import {getMobile} from "../features/user/settingsSlice";
import {useSelector} from "react-redux";
import DesktopBufferOutlet from "../../desktop/features/outlet/DesktopBufferOutlet";
import MobileBufferOutlet from "../../mobile/features/outlet/MobileBufferOutlet";
import {selectIsAuth} from "../features/auth/authSlice";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
const RequireAuth =  ()  =>  {
    const isMobile = useSelector(getMobile);
    let content;
    const isAuth = useSelector(selectIsAuth)
    const location = useLocation();

    if(isAuth){
        if (isMobile) {
            content = <MobileBufferOutlet />
        }
        else{
            content = <DesktopBufferOutlet />
        }
    }
    else{
        content = <Navigate to="/" state={{from: location}} replace />
    }


    return content;
}

export default RequireAuth;