import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router";
import {Outlet, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getLanguage,

    getLocalizedLanguage, setLanguage,

} from "../features/user/settingsSlice";
import {useTranslation} from "react-i18next";
import {selectIsAuth} from "../features/auth/authSlice";
import {useUpdateLanguageMutation} from "../features/user/userApiSlice";
import useToolService from "../hooks/useToolService";

const LanguageRouteWrapper = () => {
    const location = useLocation();
    const {allowedShortLanguages} = useToolService()
    const {i18n } = useTranslation();
    const navigate = useNavigate();
    const {languageCode} = useParams();
    const currentLanguage = useSelector(getLocalizedLanguage)
    const language = useSelector(getLanguage)

    const dispatch = useDispatch()
    const isAuth = useSelector(selectIsAuth);
    const [updateLanguage] = useUpdateLanguageMutation()
    useEffect(()=>{
        let language;

        switch (i18n.language.toUpperCase()){
            case "RU" || "RU-RU":
                language = "RU";
                break;
            case "ES":
                language = "ES";
                break;
            case "DE":
                language = "DE";
                break;
            case "FR":
                language = "FR";
                break;
            case "KZ":
                language = "KZ";
                break;
            case "CN":
                language = "CN";
                break;
            default:
                language = "US";

        }
       /*
        if(i18n.language.toUpperCase() === "RU" || i18n.language.toUpperCase() === "RU-RU"){

            language = "RU"
        }else{

            language = "US"
        }
*/
        dispatch(setLanguage(language))



    },[])
    useEffect(() => {


        if(allowedShortLanguages.includes(languageCode)){
            const newestLanguageCode = languageCode.toLowerCase() === "en" ? "US" : languageCode.toUpperCase()

            if(isAuth){
                updateLanguage(newestLanguageCode)
            }
            i18n.changeLanguage(newestLanguageCode)
        }else{

            navigate("/error")
        }



    }, [languageCode]);

    useEffect(() => {

        const urlArray = location.pathname.split('/')
        navigate('/'+currentLanguage+'/'+urlArray.slice(2,).join("/")+location.search)
    },[currentLanguage, language])


    return <Outlet/>
}

export default LanguageRouteWrapper;