import {useTranslation} from "react-i18next";
import useToolService from "../../hooks/useToolService";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, getMobile, setGlobalLoader} from "../user/settingsSlice";
import {Link, useSearchParams} from "react-router-dom";
import Table from "../../../admin/component/ui/table/Table";
import {useGetResultsQuery} from "./resultsSlice";

import {SelectColumnFilter} from "../../../admin/utils/Filters";
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import {Li} from "react-flags-select";


const Results = () => {
    const [searchParams] = useSearchParams()
    const { t } = useTranslation();
    const {getTranslatedValue, getIcon, downloadEssay} = useToolService();
    const isMobile = useSelector(getMobile)
    const dispatch = useDispatch()
    const {data, isLoading, isSuccess, isError, error} = useGetResultsQuery()
    const language = useSelector(getLocalizedLanguage)
    //    color: #6bc8ff;
    const columns = [

        {
          Header: t("Title"),
          accessor: 'titles',
            Cell: ({ value, row }) => (<Link to={"/"+language+row.original.alias }>
                {getTranslatedValue(value)}
                {
                    row.original.titleExtra &&
                    <><br/><span className="extraTitles">{row.original.titleExtra}</span></>
                }
            </Link>),

        },
        {
            Header: t("Result"),
            accessor: 'result',
            disableFilters:true,
            show: true,
            Cell:  ({ value, row }) => {
                switch (row.original.testType){
                    case "Test":
                        return <span className="tableResultP">{value}</span>
                    case "Essay":
                        const parsed = JSON.parse(row.original.result)
                        const val = parsed.value;
                        return <div className="downloadBlock" onClick={()=>downloadEssay(parsed.id,val)}>
                                    <img className="shortIcon" src={getIcon(val.substring(val.indexOf(".")+1))}/>
                            </div>
                }

            },
        },
        {
            Header: t("Type"),
            accessor: 'testType',
            filterable : false,
            Cell:  ({ value }) => value,
            Filter: SelectColumnFilter,
            filter: 'inclFilterFn',


        },
        {
            Header: t("Date"),
            accessor: 'dateTime',
            Cell:  ({ value}) => {
            const date = new Date(value)
                const day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate()
                const month = date.getMonth()+1
                const hour = date.getHours() < 10 ? "0"+date.getHours() : date.getHours()
                const minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes()
                return <div>
                    {day+"."+month+"."+date.getFullYear()}
                    <br/>
                    {hour+":"+minutes}
                </div>
            },
            disableFilters:true,
        },
        ]

    let content;

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])

    if(isSuccess){
        if(data.length === 0){
            content = <div className="noResults">{t("NoResults")}</div>
        }else{
            content = <Table columns={columns} data={data} pageSizeRaw={10} fit={true}/>
        }


    }

    return  <><Helmet>
        <title>{t("Results")}</title>
        <meta name='description' content="Panel" />
    </Helmet>


    <div>
        <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide">
                    <h2>{t("Results")}</h2>
                    <div className={isMobile ? "overflowScrollX": ""}>
                        {content}
                    </div>

                </div>
            </div>
        </div>
    </div>
        </>
}

export default Results;