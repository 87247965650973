import {useGetAllProductsQuery} from "./productsSlice";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../user/settingsSlice";
import useToolService from "../../hooks/useToolService";
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";

import MainPageTestCatalogMobile from "../../../mobile/features/tests/MainPageTestCatalogMobile";
import MainPageTestCatalog from "../../../desktop/features/tests/MainPageTestCatalog";
import {useTranslation} from "react-i18next";

const Products = () => {
    const isMobile = useSelector(getMobile)
    const {t} = useTranslation()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery();
    const dispatch = useDispatch()
    let content;


    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])


    if(isSuccess){

        content = isMobile
            ? <MainPageTestCatalogMobile data={data} removeTitle={true} />
            : <MainPageTestCatalog data={data} removeTitle={true} />
    }
    const {getLanguageLinksForPages, prepareMeta} = useToolService();

    return <>
        <Helmet   link={getLanguageLinksForPages("/products/")}
                  meta={prepareMeta(t("Products")+" Many Tests", t("Products")+" Many Tests", "website", "/", "")}
                  title={t("Products")+" Many Tests"}
        />
        <div className={isMobile ? "wrapper2": "wrapper"}>
        <div className="buyCard">
            <div className="buyCardInsideWide">
                <h1>{t("Products")}</h1>
        {
            content
        }
            </div>
        </div>
        </div>
    </>
}

export default Products;