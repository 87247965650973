import {
    CREATE_USER_REQUEST,
} from "../../app/repository";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import RootAddress from "../../../API/RootAddress";

export const userRequestsSlice =  createApi({
    reducerPath: 'userRequestsSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: RootAddress.getURL,
    }),
    tagTypes: ['UserRequests'],
    endpoints: builder => ({
        createUserRequest: builder.mutation({
            query: (formData) => {
                return {
                    url: CREATE_USER_REQUEST,
                    method: 'POST',
                    body: formData,
                }

            }
        }),

    })
})

export const {
    useCreateUserRequestMutation
} = userRequestsSlice