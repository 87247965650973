import {apiSlice} from "../../../common/app/api/apiSlice";
import {BUY, GET_FREE} from "../../../common/app/repository";

export const purchaseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        buy: builder.mutation({
            query: (object) => ({
                url: BUY,
                method: "POST",
                body: object
            }),
            invalidatesTags:  ['Orders', 'EssayConversations']
        }),
        getFree: builder.mutation({
            query: (object) => ({
                url: GET_FREE,
                method: "POST",
                body: object
            }),
            invalidatesTags:  ['Orders', 'EssayConversations']
        })
    })
})

export const {
    useBuyMutation,
    useGetFreeMutation
} = purchaseApiSlice