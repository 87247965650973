import {useEffect, useState} from "react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLastName, selectLastNameError, setLastname, setLastnameError, setIsLastnameValid} from "../../features/auth/authSlice";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../features/user/settingsSlice";
import useToolService from "../../hooks/useToolService";



const InputLastname = ({classes}) => {
    const lastName = useSelector(selectLastName);
    const { t } = useTranslation();
    const language = useSelector(getLanguage)
    const dispatch = useDispatch();

    const {isTooBig} = useToolService()
    const lastNameError = useSelector(selectLastNameError)


    useEffect(()=>{
        if(lastName && lastName.length !== 0) {
            if (isTooBig(lastName)) {
                dispatch(setLastnameError(t("TooBig")))
                dispatch(setIsLastnameValid(false))
            } else {
                dispatch(setLastnameError(""))
                dispatch(setIsLastnameValid(true))
            }
        }else{
            dispatch(setLastnameError(""))
            dispatch(setIsLastnameValid(false))
        }
    }, [lastName, language])

    return  <div className="inputDivWithMessage">
        <input className={lastNameError.length>0 ? "redBorder "+classes : classes} type="text" value={lastName} placeholder={t('LastName')} onChange={(e)=>dispatch(setLastname(e.target.value))} />
        <div>{lastNameError}</div>
    </div>
}

export default InputLastname;