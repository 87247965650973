import React from "react";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";
import {

    getVisibleForgotPasswordModal,

    setForgotPasswordModal,
    getVisibleSuccessModal, setVisibleSuccessModal,

} from "../features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import InputEmail from "./UI/InputEmail";
import useToolService from "../hooks/useToolService";

import {useState} from "react";
import {useResetMutation} from "../features/auth/authApiSlice";
import {CheckCircle} from "@mui/icons-material";
import {selectEmail, selectIsEmailValid, setEmailError, setIsEmailValid} from "../features/auth/authSlice";


const ForgotPasswordModal = () => {
    const [statusLoader, setStatusLoader] = useState(false)
    const visibleForgotPasswordModal = useSelector(getVisibleForgotPasswordModal);
    const email = useSelector(selectEmail);
    const isEmailValid = useSelector(selectIsEmailValid)
    const [successReset, setSuccessReset] = useState(false)
    
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [resetApi] = useResetMutation();
    const {registrationHandler, loginHandler} = useToolService()

    const customClose = () => {
        dispatch(setForgotPasswordModal(false))
        setSuccessReset(false)
    }

    const reset = () => {

        if(email.length === 0){
            dispatch(setEmailError(t("InvalidEmptyField")))
            dispatch(setIsEmailValid(false));
        }
       
        if(isEmailValid){
            setStatusLoader(true)
            resetApi(email).unwrap().then(()=>{
                setStatusLoader(false)
                setSuccessReset(true)
            }).catch(error=>{
                setStatusLoader(false)
                switch (error.status){
                    case 409:
                        dispatch(setEmailError(t("UserNoExist")))
                        dispatch(setIsEmailValid(false));
                        break;
                    case 400:
                        switch (error.data.message){
                            case "email":
                                dispatch(setEmailError(t("InvalidEmailAddress")))
                                dispatch(setIsEmailValid(false))
                                break;
                        }
                        break;
                }
            })
        }

    }

    return <div style={{display: visibleForgotPasswordModal ? "block" : "none"}}>
        <WhiteBackgroundForModal modalTitle={successReset ? <CheckCircle className="successColor modalIcon"/> : t("ForgotPassword")} modalDescription={successReset ? t("SuccessRegistration") : t("PleaseEnterEmail")} close={()=>customClose()}><div className="formInputs">

            {!successReset &&   <><InputEmail classes="modalInput" />
                <button onClick={()=>reset()} type="button" className="redButton modalButtons flexCenterCenter">{t('Reset')}<img style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader" src="/img/spinLoader.gif" alt="Loading..."/></button>
                <div className="loginModalBottomLinksBlock">
                    <div className="registrationLinkModal" onClick={()=>loginHandler()}>{t("Login")}</div>
                    <div className="registrationLinkModal" onClick={()=>registrationHandler()}>{t("Registration")}</div>
                </div>
            </>
            }
    </div>
    </WhiteBackgroundForModal></div>






}

export default ForgotPasswordModal;