import RootAddress from "../../API/RootAddress";

/* AUTH */
export const LOGIN = RootAddress.getURL+'/api/login/';
export const REFRESH_TOKEN = RootAddress.getURL+'/api/refreshtoken';
export const ME = RootAddress.getURL+'/api/user/me';
export const CHECK = RootAddress.getURL+'/api/check';
export const REGISTRATION = RootAddress.getURL+'/api/registration/';
export const CONFIRMATION = RootAddress.getURL+'/api/confirmation/';
export const RESET = RootAddress.getURL+'/api/reset-password/';
export const NEW_PASSWORD_RESET = RootAddress.getURL+'/api/new-password/';
export const HARD_PASSWORD_RESET =  RootAddress.getURL+'/api/reset/';

/* FILES */
export const UPLOAD_FILE = RootAddress.getURL+'/api/v1/files/upload';
export const UPLOAD_ESSAY = RootAddress.getURL+'/api/v1/files/uploadEssay';
export const CREATE_FILE = RootAddress.getURL+'/api/v1/files/create';
export const GET_FILE = RootAddress.getURL+'/api/v1/files/get?id=';

/*PRODUCTS*/
export const GET_ALL_PRODUCTS = RootAddress.getURL+'/api/v3/product/list';
export const GET_PRODUCT_BY_ID = RootAddress.getURL+ '/api/v3/product/get?id=';
export const CHECK_PAID_PRODUCT_BY_ID = RootAddress.getURL+ '/api/v1/order/checkPaid?id=';
export const GET_PRODUCT_BY_ALIAS = RootAddress.getURL+ '/api/v3/product/getByAlias?alias=';


/* USER_REQUESTS */
export const CREATE_USER_REQUEST = RootAddress.getURL+'/api/v3/user_request/create';


/* PRICE REQUEST */
export const GET_REQUEST_PRICE_BY_PRODUCT_ID = RootAddress.getURL+'/api/v3/requestPrice/getByProductId?id=';
export const CREATE_REQUEST_PRICE = RootAddress.getURL+'/api/v3/requestPrice/create';

/* CHECKS */
export const GET_CHECK_BY_ID = RootAddress.getURL+'/api/v1/check/get?id=';
export const UPDATE_CHECK_PAID = RootAddress.getURL+'/api/v1/check/edit/setPaid';


/* SERVICES */
export const GET_CURRENCIES = RootAddress.getURL+'/api/v3/currency';

/*ORDERS*/
export const BUY = RootAddress.getURL+'/api/v1/order/buy';
export const GET_FREE = RootAddress.getURL+'/api/v1/order/getFree';
export const GET_MY_ORDERS = RootAddress.getURL+'/api/v1/order/list';
export const GET_ORDER = RootAddress.getURL+'/api/v1/order/get';
export const UPDATE_ESSAY_CONVERSATION_SET_ORDER = RootAddress.getURL+""

/* USERS */
export const USER_UPDATE_AVATAR = RootAddress.getURL+'/api/v1/users/updateAvatar';
export const USER_UPDATE_FIRSTNAME = RootAddress.getURL+'/api/v1/users/updateFirstname';
export const USER_UPDATE_LASTNAME = RootAddress.getURL+'/api/v1/users/updateLastname';
export const USER_UPDATE_PHONE = RootAddress.getURL+'/api/v1/users/updatePhone';
export const USER_UPDATE_PASSWORD = RootAddress.getURL+'/api/v1/users/updatePassword';
export const USER_UPDATE_LANGUAGE = RootAddress.getURL+'/api/v1/users/updateLanguage';
export const GET_A_MANAGER = RootAddress.getURL+'/api/v3/users/getRandom';

/* ESSAY */
export const CREATE_ESSAY_CONVERSATION = RootAddress.getURL+'/api/v1/essay_conversation/create';
export const GET_ESSAY_CONVERSATION = RootAddress.getURL+'/api/v1/essay_conversation/get';
export const CREATE_ESSAY_MESSAGE = RootAddress.getURL+'/api/v1/essay_message/create';
export const ESSAY_CONVERSATION_UPDATE_CUSTOMER_FILE = RootAddress.getURL+'/api/v1/essay_conversation/edit/setCustomerFile';
export const ESSAY_CONVERSATION_UPDATE_STATUS = RootAddress.getURL+'/api/v1/essay_conversation/edit/setStatus';
export const GET_ALL_ESSAY_CONVERSATION_FINISHED = RootAddress.getURL+'/api/v1/essay_conversation/listFinished';
//export const UPDATE_ESSAY_CONVERSATION_SET_ESSAY_FILE = RootAddress.getURL+'/api/v1/essay_conversation/UPDATE';

/* LANGUAGE_LEVEL_TEST */
export const GET_LANGUAGE_TEST = RootAddress.getURL+'/api/v1/language-level-test/get';
export const CHECK_LANGUAGE_LEVEL_TEST = RootAddress.getURL+'/api/v1/language-level-test/check?orderId=';
export const GET_RESULTS_BY_LANGUAGE_LEVEL_RESULT_ID = RootAddress.getURL+'/api/v1/language-level-test/getResult?entityId=';

/* CAT4 */
export const GET_MAIN_CAT4 = RootAddress.getURL+'/api/v1/cat4/get';
export const GET_CAT4_TEST  = RootAddress.getURL+'/api/v1/cat4/start';
export const GET_RESULT_EXAMPLE = RootAddress.getURL+'/api/v1/cat4/example';
export const CHECK_CAT4 = RootAddress.getURL+'/api/v1/cat4/check?orderId=';
export const GET_RESULTS_BY_CAT4_RESULT_ID = RootAddress.getURL+'/api/v1/cat4/getResult?entityId=';

/* RESULTS */
export const GET_RESULTS_BY_TEST = RootAddress.getURL+'/api/v1/results/getByTest?id=';
export const GET_CERTAIN_RESULTS_BY_ID = RootAddress.getURL+'/api/v1/results/getCertain?id=';
export const GET_RESULTS = RootAddress.getURL+'/api/v1/results/list';

/* ARTICLES */
export const GET_ARTICLE_BY_ALIAS = RootAddress.getURL+'/api/v3/articles/getByAlias?alias=';
export const GET_ARTICLES = RootAddress.getURL+ '/api/v3/articles/getArticles';
export const GET_SHORT_ARTICLES = RootAddress.getURL+ '/api/v3/articles/getShortArticles';


