import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    numberOfQuestions: 0,
    filledNumbers: [],
    currentQuestionId: 0,
    firstQuestionId: 0

}

const testProgressSlice = createSlice({
        name: '@@testProgressSlice',
        initialState: initialState,
        reducers: {
            setNumberOfQuestions:(state, action)=>{
                state.numberOfQuestions = action.payload;
            },
            setFilledNumbers:(state, action)=>{
                state.filledNumbers = action.payload;
            },
            setFirstQuestionId:(state, action)=>{
                state.firstQuestionId = action.payload;
            },
            setCurrentQuestionId:(state, action)=>{
                state.currentQuestionId = action.payload;
            },
            addFilledNumber:(state, action)=>{
                if(!state.filledNumbers.includes(action.payload)){
                    state.filledNumbers.push(action.payload);
                }

            },
            nextQuestion:(state)=>{

                return null
                //state.currentQuestionId += 1;
            },
            previousQuestion:(state)=>{
                state.currentQuestionId -= 1;
            },

        },

    }
)

export const {setFirstQuestionId, previousQuestion, nextQuestion, setNumberOfQuestions, setFilledNumbers, addFilledNumber, setCurrentQuestionId} = testProgressSlice.actions;
export const testProgressReducer = testProgressSlice.reducer;
export const getNumberOfQuestions = (state) => state.testProgressSlice.numberOfQuestions
export const getFirstQuestionId = (state) => state.testProgressSlice.firstQuestionId
export const getFilledNumbers = (state) => state.testProgressSlice.filledNumbers
export const getCurrentQuestionId = (state) => state.testProgressSlice.currentQuestionId
