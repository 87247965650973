import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getVisibleSuccessModal,
    getVisibleWarningCancelModal, setTestStatus,
    setVisibleSuccessModal, setVisibleWarningCancelModal
} from "../features/application/applicationSlice";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {Warning} from "@mui/icons-material";
import {
    getFirstQuestionId,
    setCurrentQuestionId,
    setFilledNumbers
} from "../features/commonTestData/testProgressLine";
import {setAnswers} from "../features/commonTestData/testAnswersSlice";
import useToolService from "../hooks/useToolService";

const WarningCancelModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const visibleWarningCancelModal = useSelector(getVisibleWarningCancelModal);
    const {resetTest} = useToolService()

    return <div style={{display: visibleWarningCancelModal ? "block" : "none"}}><WhiteBackgroundForModal modalTitle="" modalDescription="" close={()=>dispatch(setVisibleWarningCancelModal(false))}>
        <div className="successMessage">
            <div><Warning className="errorColor modalIcon"/></div>
            <div><h2>{t("WarningCancel")}</h2></div>
            <div className="loginModalBottomLinksBlock">
                <button onClick={()=>resetTest()} type="button" className="redButton modalButtons flexCenterCenter modalInput">{t('Complete')}</button>

            </div>
        </div>
    </WhiteBackgroundForModal></div>
}

export default WarningCancelModal;