import {apiSlice} from "../../app/api/apiSlice";
import {CREATE_FILE, GET_ESSAY_CONVERSATION, GET_FILE, UPLOAD_ESSAY, UPLOAD_FILE} from "../../app/repository";

export const filesSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFile: builder.query({
            query: (id) => {

                return GET_FILE+id},
            responseHandler: "application/octet-stream"

        }),
        uploadFile: builder.mutation({
            query: (formData) => ({
                url: UPLOAD_FILE,
                method: 'POST',
                body: formData,
            })
        }),
        uploadEssay: builder.mutation({
            query: (formData) => ({
                url: UPLOAD_ESSAY,
                method: 'POST',
                body: formData,

            })
        }),
        createFile: builder.mutation({
            query: (formData) => ({
                url: CREATE_FILE,
                method: 'POST',
                body: formData,

            })
        }),

    })
})

export const {
    useUploadFileMutation,
    useUploadEssayMutation,
    useCreateFileMutation,
    useGetFileQuery
} = filesSlice