import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getCurrencies} from "../user/settingsSlice";

const CurrencySelect = ({price, callback}) => {
    const currencies = useSelector(getCurrencies);
    const [currentCurrency, setCurrentCurrency] = useState(1);

    const setCurrentCurrencyHandler = (value) => {
        setCurrentCurrency(value);
        callback(value);
    }

    return currencies.length > 0 && <select className="selectCurrencyBlock"
                                             value={currentCurrency}
                                             onChange={(e) => setCurrentCurrencyHandler(Number.parseInt(e.target.value))}>
            {currencies.map((currency=>{
                return <option key={currency.CharCode} value={currency.Position}>≈{((price / Number.parseFloat(currency.Value.replace(/,/, '.')))*currency.Nominal).toFixed(2)}{currency.Symbol}</option>
            }))}</select>
}

export default CurrencySelect;