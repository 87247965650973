import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    mobile: true,
    language: "US",
    country: {country_code: "", country_name: ""},
    currentTitle: "",
    searchValue: "",
    paidProducts:[],
    currentStupidPassword: "",
    cat4Order: 0,
    serverError: false,
    globalLoader: false,
    currencies: [],


}

const settingsSlice = createSlice({
        name: '@@settings',
        initialState: initialState,
        reducers: {
            setMobile:(state, action)=>{
                state.mobile = action.payload;
            },
            setCurrencies:(state, action)=>{
                state.currencies = action.payload;
            },
            setServerError:(state, action)=>{
                state.serverError = action.payload;
            },
            setGlobalLoader:(state, action)=>{
                state.globalLoader = action.payload;
            },
            setCat4Order:(state, action)=>{
                state.cat4Order = action.payload;
            },
            setCurrentStupidPassword:(state, action)=>{
                state.currentStupidPassword = action.payload;
            },
            setSearchValue:(state, action)=>{
                state.searchValue = action.payload;
            },
            setPaidProducts:(state, action)=>{
                state.paidProducts = action.payload;
            },

            setLanguage:(state, action)=>{
                state.language = action.payload;
            },
            setCountry:(state, action)=>{
                state.country = action.payload;
            },
            setCurrentTitle:(state, action)=>{
                state.currentTitle = action.payload;
            },
        },
    }
)

export const {setCurrencies, setGlobalLoader, setServerError, setCat4Order, setCurrentStupidPassword, setPaidProducts, setSearchValue, setCurrentTitle, setMobile, setLanguage, setCountry} = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
export const getMobile = (state) => state.settings.mobile
export const getCat4Order = (state) => state.settings.cat4Order
export const getCountry = (state) => state.settings.country
export const getGlobalLoader = (state) => state.settings.globalLoader
export const getServerError = (state) => state.settings.serverError
export const getLanguage = (state) => state.settings.language
export const getCurrentStupidPassword = (state) => state.settings.currentStupidPassword
export const getPaidProducts = (state) => state.settings.paidProducts
export const getCurrencies = (state) => state.settings.currencies
export const getLocalizedLanguage = (state) => {

  if(state.settings.language==="US"){
      return "en";
  }
  else{
      return state.settings.language.toLowerCase()
  }
}
export const getCurrentTitle = (state) => state.settings.currentTitle
export const getSearchValue = (state) => state.settings.searchValue