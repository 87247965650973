import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logout, setCredentials} from "../../features/auth/authSlice";
import {REFRESH_TOKEN} from "../repository";
import axios from "axios";
import {setLanguage, setServerError} from "../../features/user/settingsSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: "http://192.168.88.206:8081",
    credentials: "include",
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token;

        if(token){
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers;
    }

})


const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

        if(result?.error?.status){

            if(result.error.status === "FETCH_ERROR"){
              //  api.dispatch(setServerError(true))

            }else{
                api.dispatch(setServerError(false))
            }
        if(result.error.originalStatus === 401 || result.error.status === 401){

            console.log("Sending refresh token");
            // send refresh token to get new access token

            const state = api.getState().auth;

            //const refreshResult = await baseQuery("/api/v2/refreshtoken", api, extraOptions)
            const refreshToken = state.refreshToken;

            const refreshResult = await axios.post(REFRESH_TOKEN, JSON.stringify({"refreshToken":refreshToken}), {withCredentials: true, headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'Content-Type': 'application/json'
                }}).catch((error)=>{
                api.dispatch(logout())
            })

            if(refreshResult?.data){
                const user = state.user;

                //store the new token
                const isAuth = true;

                api.dispatch(setLanguage(refreshResult.data.language))
                api.dispatch(setCredentials({...refreshResult.data, user, isAuth}))

                //retry the original query with new access token

                result = await baseQuery(args, api, extraOptions)
            }else{
                api.dispatch(logout())
            }
        } else if(result.error.originalStatus === 404){
            console.error("Error with code 404");
        }


    }

    return result;
}

export const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes: [
        'Trackers',
        'ActivityTypes',
        'Orders',
        'EssayConversations'
    ],
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})